import React from 'react'
import { useState, useEffect } from 'react';
import { ToastContainer, toast, Flip } from "react-toastify";
import { useTranslation } from 'react-i18next';
const { default: axios } = require('axios');

interface CompanyProps {
    name: string,
    id: string,
    isEdit: boolean
    licenseFromParent: string
    dataFetch: any
    status: any
}
export const CompanyInfo: React.FC<CompanyProps> = ({ name, id, isEdit, licenseFromParent, dataFetch, status }) => {
    const { t } = useTranslation();
    const [companyName, setCompanyName] = useState(name)
    const [userModal, setUserModal] = useState(false)
    const [license, setLicense] = useState(licenseFromParent);
    const handleEnableClick = async (id: string) => {
        const shouldDelete = window.confirm('Are you sure you want to enable user(s) for this company?');
        if (shouldDelete) {
            const data = new FormData();
            data.append("id", id);
            data.append("originalCompanyName", name)
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/enableUserByCompany", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                toast.success(t("User Enabled!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                dataFetch()
            }
        }
    }
    const handleDeleteClick = async (id: string) => {
        const shouldDelete = window.confirm('Are you sure you want to delete user(s) for this company?');
        if (shouldDelete) {
            const data = new FormData();
            data.append("id", id);
            data.append("originalCompanyName", name)
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/deleteUserByCompany", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                toast.success(t("User Deleted!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                dataFetch()
            }
        }

    }
    const handleDisableClick = async (id: string) => {
        const shouldDelete = window.confirm('Are you sure you want to disable user(s) for this company?');
        if (shouldDelete) {
            const data = new FormData();
            data.append("id", id);
            data.append("originalCompanyName", name)
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/disableUserByCompany", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                toast.success(t("User Disabled!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                dataFetch()
            }
        }
    }
    // handleDisableClick
    return (
        <>
            <tr className="bg-[#E7E7E7] border-b border-slate-400">
                <th scope="row" className="px-6 py-4 font-bold, text-[25px] whitespace-nowrap">
                    {name}
                </th>
                <td className="px-6 py-4 text-neutral-500">
                </td>
                <td className="px-6 py-4 text-neutral-500">
                </td>
                <td className="px-6 py-4 text-neutral-500">
                </td>
                <td className="px-6 py-4 text-neutral-500">
                    {licenseFromParent ? licenseFromParent : "trial"}
                </td>
                <td className="float-right px-6 py-4">
                    {
                        isEdit ? <>
                            {status == "deleted" ? <><button className="font-medium text-green-600 hover:underline" onClick={() => {
                                handleEnableClick(id)
                            }}>Enable</button></> : <>
                                <button className="font-medium text-blue-600 hover:underline" onClick={() => {
                                    setUserModal(true)
                                    setCompanyName(name)
                                    setLicense(licenseFromParent ? licenseFromParent : "trial")
                                }}>Edit</button>
                                <button className="ml-[20px] font-medium text-yellow-400" onClick={() => handleDisableClick(id)}>Disable</button>
                                <button className="ml-[20px] font-medium text-red-600 " onClick={() => handleDeleteClick(id)}>Delete</button>
                            </>}

                        </> : <></>
                    }
                </td>
            </tr>
            {userModal ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                    >
                        <div className="relative w-[360px] my-6 mx-auto md:w-[50%]">
                            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                                    <h3 className="text-3xl font-semibold text-black">
                                        {t("Add/Edit Company")}
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                        onClick={() => setUserModal(false)}
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-black outline-none opacity-5 focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto p-6">
                                    <div className='flex text-black text-[16px] mt-[-8px]'>
                                        <p className='font-medium'>{t("Company Name")}</p>
                                    </div>
                                    <div className='flex-row mt-[8px]'>
                                        <input type="text" id="companyName" value={companyName} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-3" placeholder={t("Company Name")} required onChange={(e) => {
                                            setCompanyName(e.target.value)
                                        }} />
                                    </div>
                                    <div className='flex-row mt-[8px]'>
                                        <select data-te-select-init value={license} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-6" onChange={(e) => {
                                            setLicense(e.target.value)
                                        }}>
                                            <option value="trial">Trial</option>
                                            <option value="basic">Basic</option>
                                            <option value="basic extended">Basic extended</option>
                                            <option value="professional">Professional</option>
                                        </select>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                                    <button
                                        className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                        type="button"
                                        onClick={() => {
                                            setCompanyName("")
                                            setLicense("")
                                            setUserModal(false)
                                        }}
                                    >
                                        {t("Close")}
                                    </button>
                                    <button
                                        className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                        type="button"
                                        onClick={async () => {
                                            const data = new FormData();
                                            data.append("id", id);
                                            data.append("originalCompanyName", name)
                                            data.append("companyName", companyName);
                                            data.append("license", license);
                                            const result = await axios.post(process.env.REACT_APP_API_URL + "api/editUserByCompany", data, {
                                                headers: {
                                                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                                                    "Content-Type": "application/json",
                                                }
                                            });
                                            setUserModal(false)
                                            if (result.status === 200) {
                                                toast.success(t("User Edited!"), {
                                                    position: "top-right",
                                                    autoClose: 1000,
                                                    hideProgressBar: true,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: 0,
                                                    toastId: "my_toast",
                                                });
                                                dataFetch()
                                            }
                                        }}
                                    >
                                        {t("Save Changes")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </>
            ) : null}
        </>
    )
}