import { useEffect, useState } from "react";
import { Redirect, Route, useLocation, useParams } from "react-router-dom";
import { parseJWT } from "../utils";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
const { default: axios } = require('axios');

const PrivateRoute = (props: any) => {
  const { t } = useTranslation();
  const [toastId, setToastId] = useState<any>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const tender_id = urlParams.get('tender_id');
  const [token, setToken] = useState(localStorage.getItem("tender_auth"));
  const dataFetch = async () => {
    if (localStorage.getItem("tender_auth")) {
      let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
      let params = {
        email: user?.sub?.email,
      };
      try {
        axios
          .post(process.env.REACT_APP_API_URL + "api/checkUserLogin", params)
          .then(function (response: any) {
            localStorage.setItem("tender_auth", response.data.token);
          })
          .catch(function (error: any) {
            localStorage.removeItem("tender_auth")
            setToken(null);
          });
      } catch (error) {
        console.log(error)
      }
    } else {
      setToken(null);
    }
  }


  useEffect(() => {
    dataFetch();
  }, [])

  if (email && tender_id) {
    localStorage.setItem("email", email)
    localStorage.setItem("tender_id", tender_id)
    return <Redirect to="/"/>
  }
  return <>{token ? <Route {...props} /> : <Redirect to="/login" />}</>;
};

export default PrivateRoute;
