import React, { FC, useState, useEffect, } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Navbar } from "../Navbar";
import { parseJWT } from "../../utils";
import { UserPage } from "./User";
import { LoadingBar } from "../LoadingBar";
import { useTranslation } from 'react-i18next';
import { CompanyInfo } from "./Company";
const { default: axios } = require('axios');
import { ToastContainer, toast, Flip } from "react-toastify";
import i18n from "../../i18n";

type SomeComponentProps = RouteComponentProps;
const UserManagement: FC<SomeComponentProps> = ({ history }) => {
    let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
    const [userData, setUserData] = useState<any>([]);
    const [savedCompanies, setSavedCompanies] = useState<any>([]);
    const { t } = useTranslation();
    const [userModal, setUserModal] = useState(false);
    const [isSaveStarted, setSaveStarted] = useState(false)
    const [isShow, showCompanies] = useState(false)
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("")
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [license, setLicense] = useState("");
    const [isEdit, setEdit] = useState(false)
    const [userID, setUserID] = useState("")
    const dataFetch = async () => {
        try {
            const data = new FormData();
            data.append("role", user?.sub?.role ? user?.sub?.role : "Member")
            data.append('id', user?.sub?.id)
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getUsers", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            setUserData(result.data.data);
        } catch (error) {
            console.log(error)
        }
    }
    const getSavedCompanies = async () => {
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/getSavedCompanies", {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        setSavedCompanies(result.data.data);
        console.log(result.data.data)
    }
    const registerCompany = async () => {
        const data = new FormData();
        data.append("companyName", companyName)
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/registerCompany", data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status == 200) {
            if (result.data.message == "Company registeration successed") {
                const successMessage = i18n.language == "no" ? "Bedriftsregistreringen er fullført!" : "Company registeration successed!";
                toast.success(successMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
            }
            if (result.data.message == "Already exists") {
                const existsMessage = i18n.language == "no" ? "Selskapet eksisterer" : "Company exists";
                toast.warning(existsMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
            }
        }
    }
    const fetchSuggestions = async (filter) => {
        if (filter.length < 3) {
            getSavedCompanies()
            return;
        }
        const response = await fetch(process.env.REACT_APP_API_URL + `api/suggestions?query=${filter}`);
        const data = await response.json();
        setSavedCompanies(data);
    };
    useEffect(() => {
        dataFetch()
        if(user?.sub?.role === "Super Admin"){
            getSavedCompanies()
        }
    }, [])

    const handleEditClick = async (userData: any, companyName: any, ownerUserId: any) => {
        setEdit(true)
        setUserID(userData?.id)
        setName(userData?.name)
        setEmail(userData?.email)
        setCompanyName(companyName)
        setRole(userData?.role)
        setLicense(userData?.membership)
        setUserModal(true)
    }

    const handleDeleteClick = async (userData: any) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this user?');
        if (shouldDelete) {
            console.log(userData)
            const data = new FormData();
            data.append("id", userData?.id);
            data.append("email", userData?.email);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/deleteUser", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                toast.success(t("User Deleted!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                dataFetch()
            }
        }
    }
    return (
        <div className="leading-normal tracking-normal text-black">
            <Navbar selectedTab={""} openedDocument={""} selectedDocumentList={[]} previousOpenedDocument={""} selectedFolderName={""} isFolderContainFulldocuments={false} opportuniteisDataFetch={() => {
            }} promptDataFetch={() => { }} setSettingFinished={() => { }} />
            <div className="flex-row">
                <div className="flex items-center mx-[40px] mt-[40px]">
                    <div className="flex-row">
                        <h1 className="text-[20px]">{t("Users")}</h1>
                        <p className="text-neutral-500">{t("user_manage_description")}</p>
                    </div>
                    <div className="flex-none absolute right-[30px]">
                        <button className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700" onClick={() => {
                            if (userData.length == 3 && user?.sub?.membership == "basic extended") {
                                toast.error(t("Reached limit for numbers of users. Please upgrade your license."));
                                return false
                            }
                            setUserModal(true)
                            setEdit(false)
                        }}>{t("Add User")}
                        </button>
                    </div>
                </div>
                <div className="relative overflow-x-auto w-[95%] mx-[40px]">
                    <table className="w-full text-sm text-left ">
                        <thead className="text-xs bg-white">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    {t("Name")}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t("Email")}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t("Role")}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t("Verify Status")}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t("Lisense")}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData.map((item, index: number) => {
                                return (
                                    <React.Fragment key={index}>
                                        <CompanyInfo status={item?.status} name={item?.companyName} id={item?.ownerUserId} isEdit={user?.sub?.role === "Super Admin" ? true : false} licenseFromParent={item?.membership} dataFetch={dataFetch}></CompanyInfo>
                                        <UserPage status={item?.status} userData={item?.users} handleEditClick={handleEditClick} companyName={item?.companyName} ownerUserId={item?.ownerUserId} handleDeleteClick={handleDeleteClick} licenseCompany={item?.membership}></UserPage>
                                    </React.Fragment>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </div>

            {userModal ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                    >
                        <div className="relative w-[360px] my-6 mx-auto md:w-[50%]">
                            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                                    <h3 className="text-3xl font-semibold text-black">
                                        {t("Add/Edit User")}
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                        onClick={() => setUserModal(false)}
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-black outline-none opacity-5 focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto p-6">
                                    <div className='flex text-black text-[16px] mt-[-8px]'>
                                        <p className='font-medium'>{t("Name")}</p>
                                    </div>
                                    <div className='flex-row mt-[8px]'>
                                        <input type="text" id="userName" value={name} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-3" placeholder={t("Name")} required onChange={(e) => {
                                            setName(e.target
                                                .value)
                                        }} />
                                    </div>
                                    <div className='flex text-black text-[16px] mt-[-8px]'>
                                        <p className='font-medium'>{t("Company Name")}</p>
                                    </div>
                                    <div className='flex-row mt-[8px] relative'>
                                        <input type="text" id="companyName" value={companyName} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-3" placeholder={t("Company Name")} required onFocus={() => {
                                            showCompanies(true)
                                        }} onChange={(e) => {
                                            setCompanyName(e.target.value)
                                            if(user?.sub?.role === "Super Admin"){
                                                fetchSuggestions(e.target.value)
                                            }
                                        }} />
                                        {user?.sub?.role === "Super Admin" && savedCompanies.length > 0 && isShow && (
                                            <div id="suggestions" className="absolute z-10 w-full bg-white suggestions top-10">
                                                {savedCompanies.map((company, index) => (
                                                    <div key={index} className="text-black border suggestion-item" onClick={() => {
                                                        setCompanyName(company.name)
                                                        showCompanies(false)
                                                    }}>
                                                        {company.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div className='flex text-black text-[16px] mt-[-8px]'>
                                        <p className='font-medium'>{t("Email")}</p>
                                    </div>
                                    <div className='flex-row mt-[8px]'>
                                        <input type="email" id="email" value={email} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-3" placeholder={t("Email")} required disabled={isEdit} onChange={(e) => {
                                            setEmail(e.target.value)
                                        }} />
                                    </div>
                                    <div className='flex text-black text-[16px] mt-[-8px]'>
                                        <p className='font-medium'>{t("Role")}</p>
                                    </div>
                                    <div className='flex-row mt-[8px]'>
                                        <select data-te-select-init value={role} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-6" onChange={(e) => {
                                            setRole(e.target.value)
                                        }} disabled={user?.sub?.role !== "Super Admin" && role === "Super Admin"}>
                                            <option value="Member">Member</option>
                                            <option value="Admin">Admin</option>
                                            {
                                                user?.sub?.role === "Super Admin" ? <option value="Super Admin">Super Admin</option> : <></>
                                            }
                                        </select>
                                    </div>
                                    {/*<div className='flex-row mt-[8px]'>
                                        <select data-te-select-init value={license} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-6" onChange={(e) => {
                                            setLicense(e.target.value)
                                        }} disabled={user?.sub?.role !== "Super Admin"}>
                                            <option value="trial">trial</option>
                                            <option value="basic">basic</option>
                                            <option value="professional">professional</option>
                                        </select>
                                    </div>*/}
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                                    <button
                                        className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                        type="button"
                                        onClick={() => {
                                            setUserModal(false)
                                            setUserID("")
                                            setName("")
                                            setEmail("")
                                            setCompanyName("")
                                            setRole("")
                                        }}
                                    >
                                        {t("Close")}
                                    </button>
                                    {
                                        isSaveStarted ?
                                            <LoadingBar></LoadingBar>
                                            : <button
                                                className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                                type="button"
                                                onClick={async () => {
                                                    await registerCompany()
                                                    setSaveStarted(true)
                                                    if (isEdit) {
                                                        const data = new FormData();
                                                        data.append("id", userID);
                                                        data.append("name", name);
                                                        data.append("companyName", companyName);
                                                        data.append("role", role);
                                                        data.append("membership", license);
                                                        const result = await axios.post(process.env.REACT_APP_API_URL + "api/editUser", data, {
                                                            headers: {
                                                                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                                                                "Content-Type": "application/json",
                                                            }
                                                        });
                                                        setUserModal(false)
                                                        if (result.status === 200) {
                                                            toast.success(t("User Edited!"), {
                                                                position: "top-right",
                                                                autoClose: 1000,
                                                                hideProgressBar: true,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: 0,
                                                                toastId: "my_toast",
                                                            });
                                                            dataFetch()
                                                        }
                                                    } else {
                                                        let params = {
                                                            name: name,
                                                            companyName: companyName,
                                                            email: email,
                                                            role: role,
                                                            ownerUserId: user?.sub?.id
                                                        };
                                                        axios
                                                            .post(process.env.REACT_APP_API_URL + "api/registerUser", params)
                                                            .then(function (response: any) {
                                                                toast.success(response.data.message, {
                                                                    position: "top-right",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: true,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: false,
                                                                    progress: 0,
                                                                    toastId: "my_toast",
                                                                });
                                                                dataFetch()
                                                            })
                                                            .catch(function (error: any) {
                                                                toast.error(error.response.data.message, {
                                                                    position: "top-right",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: true,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: false,
                                                                    progress: 0,
                                                                    toastId: "my_toast",
                                                                });
                                                            });

                                                        setUserModal(false)
                                                    }
                                                    setSaveStarted(false)
                                                }}
                                            >
                                                {t("Save Changes")}
                                            </button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </>
            ) : null}
        </div>
    );
};

export default UserManagement;
