import { Redirect, Route } from "react-router-dom";
import { parseJWT } from "../utils";
const { default: axios } = require('axios');
import { useEffect, useState } from "react";

const UserRoleRoute = (props: any) => {
    let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
    const [token, setToken] = useState(localStorage.getItem("tender_auth"));
    const dataFetch = async () => {
        if (localStorage.getItem("tender_auth")) {
            let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
            let params = {
                email: user?.sub?.email,
            };
            try {
                axios
                    .post(process.env.REACT_APP_API_URL + "api/checkUserLogin", params)
                    .then(function (response: any) {
                        localStorage.setItem("tender_auth", response.data.token);
                    })
                    .catch(function (error: any) {
                        localStorage.removeItem("tender_auth")
                        setToken(null);
                    });
            } catch (error) {
                console.log(error)
            }
        } else {
            setToken(null);
        }

    }


    useEffect(() => {
        dataFetch();
    }, [])
    return <>{token && (user?.sub?.role == "Super Admin" || user?.sub?.role == "Admin") ? <Route {...props} /> : <Redirect to="/login" />}</>;
};

export default UserRoleRoute;
