import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const { default: axios } = require('axios');
import { parseJWT, getUrlsFromText, isWebsite, getRemainingDaysAndHours } from '../../../utils';
import { useDropzone } from 'react-dropzone';
import { toast } from "react-toastify";
import { ReactComponent as Icon2 } from "../../../assets/svg/insight_refresh.svg";
import { ReactComponent as Icon3 } from "../../../assets/svg/complete.svg";
interface UploadFilesModalProps {
    tenderID: any
    tenderList: any
    setModalOpen: any
    addDocuments: any
    setTenderList: any
    setOpenedStatus: any
    handleSelectedFolder: any
    deleteDocumentforSubmission: any
    setTabName: any
    requirement: any
}

const UploadFilesModal: React.FC<UploadFilesModalProps> = ({ tenderID, tenderList, setModalOpen, addDocuments, setTenderList, setOpenedStatus, handleSelectedFolder, deleteDocumentforSubmission, setTabName, requirement }) => {
    useEffect(() => {
        console.log(requirement)
    }, [requirement])
    const { t } = useTranslation();
    const [count, setCount] = useState(0);
    const [selectedTab, setSelectedTab] = useState<any>("tab_2");
    const [CompanyBaseTenderID, setCompanyBaseTenderID] = useState<any>();
    const [files, setFiles] = useState<File[]>([]);
    useEffect(() => {
        getCompanyBaseTenderID()
    }, [])
    const getCompanyBaseTenderID = async () => {
        const data = new FormData();
        data.append("email", user.sub.email);
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/getCompanyBaseTenderID", data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        setCompanyBaseTenderID(result.data.Id);
        console.log(result.data.Id);
    };
    const handleFilesSelected = (selectedFiles: File[]) => {
        setFiles(selectedFiles);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    // Handle files selected via drag-and-drop
    const onDrop = useCallback((acceptedFiles: File[]) => {
        handleFilesSelected(acceptedFiles);
    }, [handleFilesSelected]);

    const { getRootProps, getInputProps } = useDropzone({ maxFiles: 1, onDrop });

    // Handle files selected via the traditional input
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            handleFilesSelected(Array.from(event.target.files));
        }
    };
    let user = parseJWT(localStorage.getItem("tender_auth"));
    const uploadFiles = async () => {
        let selectedFiles = files
        let tenders = tenderList.filter(item => item.id == tenderID)
        let tender = tenders[0]
        const formData = new FormData();
        formData.append("email", user.sub.email);
        formData.append('tab', selectedTab);
        if (selectedTab == "tab_2") {
            formData.append("id", CompanyBaseTenderID);
            formData.append('folder', "Company Documents");
        } else {
            formData.append("id", tenderID);
            formData.append('folder', tender.title);
        }
        formData.append("oid", tenderID);
        formData.append('documentForSubmission', 'true');
        formData.append('description', requirement);
        selectedFiles.forEach((file) => {
            formData.append('file', file);
        });
        try {
            deleteDocumentforSubmission();
            const id = toast(
                <div className='flex items-center p-1'><Icon2 className='animate-spin' />{t("Uploading Document Files")}</div>,
                {
                    autoClose: false, // Prevent auto-close to control it manually
                    closeOnClick: true,
                    draggable: false,
                    hideProgressBar: true,
                    pauseOnHover: true,
                    progress: 0
                }
            );
            const response = await axios.post(process.env.REACT_APP_API_URL + "api/uploadDocument", formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            toast.update(id, {
                render: <div className='flex items-center p-1'><Icon3 />{t("Uploaded Docuemnt Files")}</div>
            });
            setTimeout(async () => {
                toast.dismiss(id);
                console.log(selectedFiles)
                console.log(selectedFiles.length)
                setCount(selectedFiles.length)
                const data = new FormData();
                data.append("email", user.sub.email);
                data.append("tab", "tab_1");
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/getTenders", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                let newIndex = result.data.data.filter(item => item.isFolderDeleted === 0).length
                setTenderList(result.data.data)
                handleSelectedFolder(tenderID, result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.title)
                addDocuments()
            }, 2000);
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    return (
        <>
            <div className="text-black fixed inset-0 flex items-center justify-center bg-opacity-75 p-4 z-[100]">
                <div className="flex flex-col w-full max-w-3xl bg-white rounded-lg shadow-lg">
                    {/* Modal Header */}
                    <div className="flex items-center justify-between p-4 border-b">
                        <div className="flex items-center">
                            {/* Placeholder for Logo */}
                            <img src={process.env.PUBLIC_URL + '/img/Filter_page1.png'} width={50} height={50} className='mr-[10px]'></img>
                            <h2 className="text-3xl font-semibold text-black">{t("Agent Insights")}</h2>
                        </div>
                    </div>
                    <form className='text-left text-[24px] align-middle items-center'>
                        <label className='flex items-center p-1 font-bold'>
                            <input
                                type="radio"
                                className='w-8 h-8 mx-2'
                                name="tab"
                                checked={selectedTab === "tab_2"}
                                onChange={() => setSelectedTab("tab_2")}
                            />
                            <p>{t("Save to Company Knowledge base")}</p>
                        </label>
                        <p className='px-14'>{t("Use this option when the document can be used for other thenders Later")}</p>
                        <label className='flex items-center p-1 font-bold'>
                            <input
                                type="radio"
                                className='w-8 h-8 mx-2'
                                name="tab"
                                checked={selectedTab === "tab_1"}
                                onChange={() => setSelectedTab("tab_1")}
                            />
                            <p>{t("Save to Tender in Workspace")}</p>
                        </label>
                        <p className='px-14'>{t("Use this option when the document is only for use for this tender")}</p>
                    </form>
                    {
                        <div className="flex-1 p-4 mb-6 overflow-y-auto">
                            <div className="flex mb-6 space-x-4">
                                {/* Left Column: List of Files */}
                                <div className="w-1/2 p-2 border-r">
                                    {files.length > 0 && (
                                        <ul className="text-sm">
                                            {files.map((file, index) => (
                                                <li className="mb-1" key={index}>{file.name}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                {/* Right Column: Upload Area */}
                                <div className="flex flex-col items-center justify-center w-1/2 p-2 border-l">
                                    <div className="w-full p-4 text-center bg-gray-100 border-2 border-gray-300 border-dashed rounded-lg">
                                        <div {...getRootProps({ className: 'dropzone' })} style={dropzoneStyle} onClick={() => {
                                            return false;
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 mx-auto mb-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                                <polyline points="17 8 12 3 7 8" />
                                                <line x1="12" y1="3" x2="12" y2="15" />
                                            </svg>
                                            <input {...getInputProps()} />
                                            <p>{t("Drag and drop files here")}</p>
                                        </div>
                                        <p className="mt-2">{t("or")}</p>
                                        <input type="file" id="manualFileUpload" onChange={handleFileChange} style={fileInputStyle} />
                                        <p onClick={() => {
                                            let inputDiv = document.getElementById("manualFileUpload")
                                            inputDiv?.click();
                                        }} className="mt-2 font-bold text-blue-900 underline cursor-pointer">{t("Choose from Computer")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* Modal Footer */}
                    <div className="flex justify-end p-4 mt-auto border-t">
                        <button
                            onClick={closeModal}
                            className="px-6 py-2 mb-1 mr-1 font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none text-[20px]"
                        >
                            {t("Close")}
                        </button>
                        <button
                            onClick={async () => {
                                closeModal()
                                await uploadFiles()
                            }}
                            className="px-2 py-1 font-bold text-white bg-green-500 rounded active:bg-green-600 active:border-green-700 active:text-white text-[20px]"
                            style={{ backgroundColor: '#55B685', width: 120, height: 50 }}
                        >
                            {t("SAVE")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
// Styles (for simplicity, you can also use CSS classes)
const dropzoneStyle: React.CSSProperties = {
    border: '2px dashed #ccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    marginBottom: '20px'
};

const fileInputStyle: React.CSSProperties = {
    display: 'none',
    marginTop: '10px'
};
export default UploadFilesModal;