import React, { useState, useEffect, Children } from 'react';
import TreeItem from './TreeItem';

interface TreeProps {
    data: TreeData[];
    checkedItems: string[]
    setCheckedItems: any
}

interface TreeData {
    id: string;
    label: string;
    children?: TreeData[];
}
/**
 * Function to handle checkbox change for the tree.
 *
 * @param {string} id - The id of the checkbox item
 * @param {boolean} isChecked - The new checked state of the checkbox
 */
const Tree: React.FC<TreeProps> = ({ data, checkedItems, setCheckedItems }) => {
    /**
     * Function to handle checkbox change for the tree.
     *
     * @param {string} id - The id of the checkbox item
     * @param {boolean} isChecked - The new checked state of the checkbox
     */
    const handleCheckboxChange = (id: string, isChecked: boolean) => {
        let newCheckedIds: string[] = [...checkedItems];

        if (isChecked) {
            newCheckedIds.push(id);
        } else {
            newCheckedIds = newCheckedIds.filter((checkedId) => checkedId !== id);
        }

        setCheckedItems(newCheckedIds);
    };



    return (
        <div>
            {data.map((item) => (
                <TreeItem
                    key={item.id}
                    id={item.id}
                    label={item.label}
                    isChecked={checkedItems.includes(item.id)}
                    onCheckboxChange={(isChecked) => handleCheckboxChange(item.id, isChecked)}
                >
                    {item.children && item.children.length > 0 && <Tree data={item.children} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />}
                </TreeItem>
            ))}
        </div>
    );
};

export default Tree;