// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-tag {
  transition: all 0.3s ease;
}
.status-tag:hover {
  transform: scale(1.05);
}

@keyframes tagChange {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.status-tag {
  animation: tagChange 0.5s;
}
.download-link {
  display: block;
  margin-top: 20px;
  font-size: 18px;
  text-decoration: none;
}
.download-link:hover {
  text-decoration: underline;
}

.custom-header {
  font-size: 18px;
}

.custom-button {
  background-color: rgb(28, 61, 90);
  color: white;
  border: none;
  transition: background-color 0.3s;
}

.custom-text {
  color: black;
  cursor: pointer;
}

.trash-icon {
  color: black;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Main/RightComponent/DocumentsTable.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,qBAAqB;IACrB,YAAY;EACd;EACA;IACE,qBAAqB;EACvB;EACA;IACE,mBAAmB;IACnB,UAAU;EACZ;AACF;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,qBAAqB;AACvB;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".status-tag {\n  transition: all 0.3s ease;\n}\n.status-tag:hover {\n  transform: scale(1.05);\n}\n\n@keyframes tagChange {\n  0% {\n    transform: scale(0.9);\n    opacity: 0.7;\n  }\n  50% {\n    transform: scale(1.1);\n  }\n  100% {\n    transform: scale(1);\n    opacity: 1;\n  }\n}\n.status-tag {\n  animation: tagChange 0.5s;\n}\n.download-link {\n  display: block;\n  margin-top: 20px;\n  font-size: 18px;\n  text-decoration: none;\n}\n.download-link:hover {\n  text-decoration: underline;\n}\n\n.custom-header {\n  font-size: 18px;\n}\n\n.custom-button {\n  background-color: rgb(28, 61, 90);\n  color: white;\n  border: none;\n  transition: background-color 0.3s;\n}\n\n.custom-text {\n  color: black;\n  cursor: pointer;\n}\n\n.trash-icon {\n  color: black;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
