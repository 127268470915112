import React, { useRef, useEffect, useState } from 'react'
import "../../App.css"


interface ViewDrawerProps {
    cssDrawer: any,
    cssHandle : any,
}


export const ViewDrawer: React.FC<ViewDrawerProps> = ({cssDrawer,  cssHandle }) => {
  return (
    <div className={cssDrawer}>
      <div className={cssHandle}></div>
    </div>
  );
}