import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Accordion } from "./Accordion";
import { getTenderLength, parseJWT } from "../../../utils";
import { ToastContainer, toast, Flip } from "react-toastify";
const { default: axios } = require('axios');
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from 'react-i18next';
import { Archived } from './Archived';

interface LeftComponentProps {
    handleDocumentClick: any,
    styleLeftSide: any,
    openedDocument: string,
    handleMetadata: any,
    handleSelectedDocuments: any,
    summaryDataFetch: any,
    handleSelectedFolder: any
    setTenderList: any
    tenderList: any
    handleSelectedTab: any
    setPreviousOpenedDocument: any
    setOpenedStatus: any
    openedStatus: any
    setCurrentSlide: any
    docList: any
    promptDataFetch: any
    setTabName: any
    tabName: any
    selectedTab: any
}
export const LeftComponent: React.FC<LeftComponentProps> = ({ handleDocumentClick, styleLeftSide, openedDocument, handleMetadata, handleSelectedDocuments, summaryDataFetch, handleSelectedFolder, setTenderList, tenderList, handleSelectedTab, setPreviousOpenedDocument, setOpenedStatus, openedStatus, setCurrentSlide, docList, promptDataFetch,setTabName, tabName, selectedTab }) => {
    const [applyTabChange, setApplyTabChange] = useState(false)
    const [isAccordionDrag, setAccordionDrag] = useState(false);
    const [dropedIndex, setDropedIndex] = useState<any>(null);
    const [isArchivedOpened, setArchivedOpen] = useState(false);
    const [movedState, setMovedState] = useState(false);
    const [selectedDocumentList, setSelectedDocumentList] = useState<any[]>([]);
    const [uploadedFilesWithoutFolder, setUploadedFilesWithoutFolder] = useState([]);
    useEffect(() => {
        const status: any[] = openedStatus.filter(item => item?.tabName == tabName)
        if (!status[0]?.isOpened) {
            let selectedList: any[] = selectedDocumentList.filter(item => item?.tabName !== status[0]?.tabName);
            setSelectedDocumentList(selectedList)
        }
        localStorage.setItem('folderStatus', JSON.stringify(openedStatus));
    }, [openedStatus])

    useEffect(() => {
        if (!applyTabChange) {
            setTimeout(() => {
                setTabName("tab_1");
                setApplyTabChange(true);
            }, 500);
        }
    }, [])
    const { t } = useTranslation();
    let user = parseJWT(localStorage.getItem("tender_auth"));

    const addData = () => {
        setTenderList([...tenderList, { title: tabName == "tab_1" ? t('Workspace title') : t("Knowledge title"), documents: [], isSaved: false }]);
    }

    const dataFetch = async () => {
        try {
            const data = new FormData();
            data.append("email", user.sub.email);
            data.append("tab", tabName);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getTenders", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            setTenderList(result.data.data);
            let openedStatusPerTab = openedStatus.filter(item => item?.tabName == tabName)[0]
            let folderData: any = result.data.data[openedStatusPerTab?.openedIndex]
            if (openedStatusPerTab?.isOpened) {
                handleSelectedFolder(folderData?.isFolderDeleted != 1 ? folderData?.id ? folderData?.id : null : null, folderData?.isFolderDeleted != 1 ? folderData?.title ? folderData?.title : null : null)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if(movedState == false){
            dataFetch();
        }
        handleSelectedTab(tabName)
    }, [tabName])

    const saveTender = async (id: any, tender_title: string) => {
        try {
            let isCreating = (id === undefined ? true : false);
            if (isCreating) {
                const data = new FormData();
                data.append("email", user.sub.email);
                data.append("title", tender_title);
                data.append("tab", tabName);
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/createTender", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    let newIndex = result.data.data.filter(item => item.isFolderDeleted === 0).length
                    setTimeout(setTenderList(result.data.data), 0)
                    setOpenedStatus((prevTabs) => {
                        return prevTabs.map((tab) => {
                            if (tab.tabName === tabName) {
                                return { ...tab, isOpened: true, openedIndex: newIndex > 0 ? newIndex - 1 : 0 };
                            } else {
                                return tab;
                            }
                        });
                    });
                    handleSelectedFolder(result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.id, result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.title)
                    toast.success(t("Folder Created!"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                }
                return result?.data?.tender
            } else {
                const data = new FormData();
                data.append('id', id);
                data.append("title", tender_title);
                data.append("tab", tabName);
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/updateTender", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    toast.success(t("Folder Updated!"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                    dataFetch();
                }
            }
            return null
        } catch (error) {
            return null
            console.log(error)
        }
    }
    const dbSaveSuccess = async () => {
        dataFetch();
    }

    const collaspe = (index: any, status: boolean) => {
        setOpenedStatus((prevTabs) => {
            return prevTabs.map((tab) => {
                if (tab.tabName === tabName) {
                    return { ...tab, isOpened: status, openedIndex: index };
                } else {
                    return tab;
                }
            });
        });
        if (status) {
            let selectedListForAnotherTab: any[] = selectedDocumentList.filter(item => item?.tabName !== tabName);
            setSelectedDocumentList([...[], ...selectedListForAnotherTab])
            handleSelectedFolder(tenderList[index]?.id, tenderList[index]?.title)
        } else {
            handleSelectedFolder(null, null)
        }
        setDropedIndex(null);
    }

    const documentClick = (document: string, action_type: string) => {
        handleDocumentClick(document, action_type);
    }

    const reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const onDragEnd = async (result: any) => {
        if (!result.destination) {
            return;
        }
        let data: any = new FormData();
        data.append("email", user.sub.email);
        if (result.draggableId.includes("folder")) {
            if (result.destination.droppableId.includes("folder")) {
                //Folder Move to Another Folder
                const items = reorder(
                    tenderList,
                    result.source.index,
                    result.destination.index
                );
                data.append("data", JSON.stringify(items));
                setOpenedStatus((prevTabs) => {
                    return prevTabs.map((tab) => {
                        if (tab.tabName === tabName) {
                            return { ...tab, openedIndex: result.destination.index };
                        } else {
                            return tab;
                        }
                    });
                });
                setTenderList(items);
            } else if (result.destination.droppableId.includes("archived")) {
                //Folder Move to Archive
                let tenderData: any = [...tenderList];
                let documentsByDrop = tenderData[parseInt(result.source.index)].deleted_documents
                tenderData[parseInt(result.source.index)].deleted_documents = documentsByDrop.concat(tenderData[parseInt(result.source.index)].documents);
                tenderData[parseInt(result.source.index)].documents = [];
                tenderData[parseInt(result.source.index)].isFolderDeleted = 1;
                data.append("data", JSON.stringify(tenderData));
                handleSelectedFolder(null, null)
                handleSelectedDocuments([], [], false)
                setTenderList(tenderData);
                try {
                    await axios.post(process.env.REACT_APP_API_URL + "api/deleteSummary", {
                        'tender_id': tenderData[parseInt(result.source.index)]?.id,
                        'email': user?.sub?.email,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                            "Content-Type": "application/json",
                        }
                    })
                    summaryDataFetch();
                } catch (error) {
                    console.log(error)
                }
            }
        } else if (result.draggableId.includes("accordion")) {
            let tenderData: any = [...tenderList];
            let documentsByDrag = tenderData[parseInt(result.source.droppableId.split("_")[2])]?.documents ? tenderData[parseInt(result.source.droppableId.split("_")[2])].documents : [];
            if (result.destination.droppableId.includes("accordion")) {
                if (parseInt(result.source.droppableId.split("_")[2]) === parseInt(result.destination.droppableId.split("_")[2])) {
                    //Document Move in same Folder
                    const documents = reorder(
                        tenderData[parseInt(result.source.droppableId.split("_")[2])].documents,
                        result.source.index,
                        result.destination.index
                    );
                    tenderData[parseInt(result.source.droppableId.split("_")[2])].documents = documents
                } else if (!result.draggableId.includes("header")) {
                    //Document to Another Folder
                    let documentsByDrop = tenderData[parseInt(result.destination.droppableId.split("_")[2])].documents;
                    documentsByDrop.splice(result.destination.index, 0, tenderData[parseInt(result.source.droppableId.split("_")[2])].documents[result.source.index]);
                    documentsByDrag.splice(result.source.index, 1);
                    tenderData[parseInt(result.source.droppableId.split("_")[2])].documents = documentsByDrag
                    tenderData[parseInt(result.destination.droppableId.split("_")[2])].documents = documentsByDrop
                }
            } else if (result.destination.droppableId.includes("archived")) {
                //Document to Archive
                let doc_id = tenderData[parseInt(result.source.droppableId.split("_")[2])].documents[result.source.index];
                let documentsByDrop = tenderData[parseInt(result.destination.droppableId.split("_")[2])]?.deleted_documents ? tenderData[parseInt(result.destination.droppableId.split("_")[2])]?.deleted_documents : [];
                documentsByDrop.splice(result.destination.index, 0, tenderData[parseInt(result.source.droppableId.split("_")[2])].documents[result.source.index]);
                documentsByDrag.splice(result.source.index, 1);
                tenderData[parseInt(result.destination.droppableId.split("_")[2])].deleted_documents = documentsByDrop
                tenderData[parseInt(result.source.droppableId.split("_")[2])].documents = documentsByDrag
                try {
                    await axios.post(process.env.REACT_APP_API_URL + "api/deleteSummary", {
                        'tender_id': tenderData[parseInt(result.source.index)]?.id,
                        'email': user?.sub?.email,
                        'document': doc_id
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                            "Content-Type": "application/json",
                        }
                    })
                    summaryDataFetch()
                } catch (error) {
                    console.log(error)
                }
                try {
                    await axios.post(process.env.REACT_APP_API_URL + "api/deleteDocumentKnowledge", {
                        'email': user.sub.email,
                        "doc_id": doc_id
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                            "Content-Type": "application/json",
                        }
                    })
                } catch (error) {
                    console.log(error)
                }
            }
            data.append("data", JSON.stringify(tenderData));
            setTenderList(tenderData);
        } else if (result.draggableId.includes("archived")) {
            let tenderData: any = [...tenderList];
            let documentsByDrag = tenderData[parseInt(result.source.droppableId.split("_")[2])]?.deleted_documents ? tenderData[parseInt(result.source.droppableId.split("_")[2])].deleted_documents : [];
            if (result.destination.droppableId.includes("archived")) {
                const documents = reorder(
                    documentsByDrag,
                    result.source.index,
                    result.destination.index
                );
                tenderData[parseInt(result.source.droppableId.split("_")[2])].deleted_documents = documents
            } else if (result.destination.droppableId.includes("accordion")) {
                //Archived => Recover
                let doc_id = tenderData[parseInt(result.draggableId.split("_")[1])].deleted_documents[result.source.index];
                let tab = tenderData[parseInt(result.draggableId.split("_")[1])].tab;
                let folder = tenderData[parseInt(result.destination.droppableId.split("_")[2])].title;
                let documentsByDrop = tenderData[parseInt(result.destination.droppableId.split("_")[2])]?.documents ? tenderData[parseInt(result.destination.droppableId.split("_")[2])].documents : [];
                documentsByDrop.splice(result.destination.index, 0, tenderData[parseInt(result.draggableId.split("_")[1])].deleted_documents[result.source.index]);
                documentsByDrag.splice(result.source.index, 1);
                tenderData[parseInt(result.destination.droppableId.split("_")[2])].documents = documentsByDrop
                tenderData[parseInt(result.draggableId.split("_")[1])].deleted_documents = documentsByDrag
                try {
                    await axios.post(process.env.REACT_APP_API_URL + "api/recoverDocumentKnowledge", {
                        'email': user.sub.email,
                        "doc_id": doc_id,
                        'tab': tab,
                        'folder': folder
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                            "Content-Type": "application/json",
                        }
                    })
                } catch (error) {
                    console.log(error)
                }
            }
            data.append("data", JSON.stringify(tenderData));
            setTenderList(tenderData);
        }
        try {
            await axios.post(process.env.REACT_APP_API_URL + "api/updateTendersByOrder", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const onDragUpdate = async (result: any) => {
        if (result?.destination?.droppableId?.includes("header")) {
            setDropedIndex(parseInt(result.destination.droppableId.split("_")[2]));
        } else if (result?.destination?.droppableId?.includes("archived")) {
            setArchivedOpen(true);
        }
    }

    const onDragStart = (result: any) => {
        if (result.draggableId.includes("accordion") || result.draggableId.includes("archived")) {
            setAccordionDrag(false);
        } else {
            setAccordionDrag(true);
        }
    }

    const collaspeArchived = () => {
        setArchivedOpen(!isArchivedOpened);
    }

    const handleDrop = (event: any) => {
        event.preventDefault();
        const chosenFiles = event.dataTransfer.files;
        const allowedExtensions = /(\.pdf|\.docx)$/i;
        const uploaded: any = [...uploadedFilesWithoutFolder];

        for (let i = 0; i < chosenFiles.length; i++) {
            const file = chosenFiles[i];
            const name = file.name;

            if (uploaded.findIndex((f: any) => f.name === name) === -1 && allowedExtensions.test(name)) {
                uploaded.push(file);
            }
        }
        setUploadedFilesWithoutFolder(uploaded)
        addData()
    };

    const handleFileInputChange = (event: any) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files)
        const allowedExtensions = /(\.pdf|\.docx)$/i;
        const uploaded: any = [...uploadedFilesWithoutFolder];
        chosenFiles.some((file) => {
            let name = file.name;
            if (uploaded.findIndex((f: any) => f.name === file.name) === -1 && allowedExtensions.exec(name)) {
                uploaded.push(file);
            }
        })
        setUploadedFilesWithoutFolder(uploaded)
        addData()
    };
    const [top, setTopPosition] = useState<any>(50);
    const handleScroll = ()=>{
        setTopPosition(document.getElementById("workSpaceScroll")?.scrollTop)
    }
    return (
        <div className="md:w-full lg:w-[16.666%] lg:min-w-[13%] bg-main_bg rounded-xl relative" style={styleLeftSide}>
            <ul className="flex flex-row text-sm font-medium text-center text-gray-500 dark:text-gray-400 border-b-solid border-[#000000] border-opacity-30 border-b-[1px]">
                <li className="flex-1 mt-[3px]">
                    <button className={`h-full w-[100%] inline-block px-4 py-3 rounded-t-lg border-solid border-[#000000] border-opacity-30 border-[1px] border-b-0 ${tabName === "tab_1" ? `text-white bg-main_color_2` : ``}`} onClick={() => setTabName("tab_1")}>{t('Workspace')}</button>
                </li>
                <li className="flex-1 mt-[3px]">
                    <button className={`h-full w-[100%] inline-block px-4 py-3 rounded-t-lg border-solid border-[#000000] border-opacity-30 border-[1px] border-b-0 ${tabName === "tab_2" ? `text-white bg-main_color_2` : ``}`} onClick={() => setTabName("tab_2")}>{t('company_knowledge_base')}</button>
                </li>
            </ul>
            <div className="mb-6 h-[84%]">
                {tabName == "tab_1" && ((tenderList.length < 3 && user?.sub?.membership == "trial") || (tenderList.length < 12 && user?.sub?.membership == "basic") || (tenderList.length < 24 && user?.sub?.membership == "basic extended") || user?.sub?.membership == "professional")?
                    <button className="flex items-center flex-row float-right pl-[10px] pr-[10px] pt-[2px] pb-[2px] text-black rounded bg-main_color_2 active:bg-[#8b4000] mr-2 text-white mt-[5px]" onClick={(e) => addData()}>
                        <svg className="h-5 w-5 mr-[3px]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                        </svg>
                        {t('New')}
                    </button>
                    :<></>
                }
                <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate} onDragStart={onDragStart} >
                    <div className="w-full h-full safari-scroll scrollbar-hide" id='workSpaceScroll' onScroll={handleScroll}>
                        <div className="container flex flex-col">
                            <Droppable droppableId="folder_droppable" isDropDisabled={!isAccordionDrag} >
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {getTenderLength(tenderList) > 0 ?
                                            tenderList.map((tender, index: any) => {
                                                return tender.isFolderDeleted === 1 ? <div key={index}></div> : <Draggable key={index} draggableId={`folder_draggable` + index} index={index}>
                                                    {(provided: any, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Accordion setOpenedStatus={setOpenedStatus} setMovedState={setMovedState} handleSelectedTab={handleSelectedTab} setTabName={setTabName} handleSelectedFolder={handleSelectedFolder} setTenderList={setTenderList} top={top} key={index} index={index} tender={tender} saveTender={saveTender} dbSaveSuccess={dbSaveSuccess} collaspe={collaspe} isOpened={((openedStatus.filter(item => item?.tabName == tabName)[0]?.openedIndex === index && openedStatus.filter(item => item?.tabName == tabName)[0]?.isOpened === true) || (dropedIndex === index)) ? true : false} documentClick={documentClick} tabName={tabName} openedDocument={openedDocument} handleMetadata={handleMetadata} handleSelectedDocuments={handleSelectedDocuments} isAnyItemOpen={openedStatus.filter(item => item?.tabName == tabName)[0]?.isOpened} summaryDataFetch={summaryDataFetch} setPreviousOpenedDocument={setPreviousOpenedDocument} selectedDocumentList={selectedDocumentList} setSelectedDocumentList={setSelectedDocumentList} setCurrentSlide={setCurrentSlide} docList={docList} uploadedFilesWithoutFolder={uploadedFilesWithoutFolder} setUploadedFilesWithoutFolder={setUploadedFilesWithoutFolder} promptDataFetch={promptDataFetch}></Accordion>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            }) : 
                                            <div className="max-w-xl mt-2" >
                                                <label
                                                    className="flex justify-center w-full h-20 mb-2 transition bg-main_bg border-2 border-gray-300 border-dashed rounded-md appearance-none hover:border-gray-400 focus:outline-none h-[120px]" onDrop={(e) => { handleDrop(e) }} onDragOver={(e) => { e.preventDefault() }}
                                                    onDragEnter={(e) => { e.preventDefault() }}>
                                                    <span className="flex items-center safari-scroll scrollbar-hide">
                                                        {
                                                            uploadedFilesWithoutFolder.length === 0 ?
                                                                <>
                                                                    <span className="italic font-medium text-[13px] text-gray-600 w-[80%] ml-[10px]">
                                                                        {
                                                                            t('add_files')
                                                                        }

                                                                    </span>
                                                                    <div>

                                                                        <svg onClick={(e) => console.log(e)} className="w-8 h-8 ml-1 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />  <polyline points="17 8 12 3 7 8" />  <line x1="12" y1="3" x2="12" y2="15" /></svg>
                                                                    </div>
                                                                </>
                                                                :
                                                                <div className='flex flex-col'>
                                                                    {
                                                                        uploadedFilesWithoutFolder.map((file: any, index) => (
                                                                            <span key={index} className="font-medium text-gray-600">
                                                                                {file.name.length > 15 ? file.name.substring(0, 10) + ".." + file.name.substring(file.name.length, file.name.length - 4) : file.name}
                                                                            </span>
                                                                        ))
                                                                    }
                                                                </div>
                                                        }
                                                    </span>
                                                    <input type="file" name="file_upload" multiple={true} className="hidden" onChange={(e) => handleFileInputChange(e)} />
                                                </label>
                                            </div>}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                    <div className="container flex flex-col absolute bottom-[1%]">
                        <Archived isOpened={isArchivedOpened} documentClick={documentClick} collaspe={collaspeArchived} documents={tenderList} openedDocument={openedDocument}></Archived>
                    </div>
                </DragDropContext>
            </div>
        </div>
    )
}

