import { I18nextProvider } from 'react-i18next';
import SignUp from "./Components/SignUp";
import Login from "./Components/Login";
import PrivateRoute from "./Auth/PrivateRoute";
import RestrictedRoute from "./Auth/RestrictedRoute";
import UserRoleRoute from './Auth/UserRoleRoute';
import Main from "./Components/Main";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import UserManagement from './Components/UserManagement';
import i18n from './i18n';
import VerifyCode from './Components/VerifyCode';
import ReSetPassword from './Components/ReSetPassword';
import CompanySignUp from './Components/CompanySignUp';
import ForgotPassword from './Components/ForgotPassword';
import ErrorBoundary from './ErrorBoundary';
import { toast, ToastContainer, Flip } from "react-toastify";
import { useEffect, useState } from 'react';
function App() {
  return (
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Switch>
            <PrivateRoute exact path="/" component={Main}/>
            <UserRoleRoute exact path="/usermanage" component={UserManagement} />
            <RestrictedRoute exact path="/login" component={Login} />
            <RestrictedRoute exact path="/register" component={SignUp} />
            <RestrictedRoute exact path="/ForgotPassword" component={ForgotPassword} />
            <RestrictedRoute exact path="/companySignUp" component={CompanySignUp} />
            <RestrictedRoute exact path="/verifyCode" component={VerifyCode} />
            <Route exact path="/setPassword" component={ReSetPassword}></Route>
            <Route exact path="/resetPassword" component={ReSetPassword}></Route>
          </Switch>
        </BrowserRouter>
      </I18nextProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />
    </ErrorBoundary>
  );
}

export default App;
