import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerLicense } from '@syncfusion/ej2-base';
import AudioRecorder from 'audio-recorder-polyfill'

window.MediaRecorder = AudioRecorder

registerLicense('ORg4AjUWIQA/Gnt2VlhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9TdEFjXnxadXNSRWlV');

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
