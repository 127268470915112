import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
const { default: axios } = require('axios');
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { RouteComponentProps } from "react-router";
import { useLocation } from 'react-router-dom';

type SomeComponentProps = RouteComponentProps;
const ReSetPassword: FC<SomeComponentProps> = ({ history }): JSX.Element => {
    const [userLang, setUserLang] = useState<string>(navigator.language);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const location = useLocation();
    const [email, setEmail] = useState("")
    const [verifyCode, setVerifyCode] = useState("verifyCode")
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('email');
        const verifyCode = searchParams.get('verifyCode');
        if (email && verifyCode) {
            setEmail(email)
            setVerifyCode(verifyCode)
        }
    }, [location.search]);

    const getLink = (data: any) => {
        let params = {
            email: data.email,
        };
        axios
            .post(process.env.REACT_APP_API_URL + "api/getResetPasswordLink", params)
            .then(function (response: any) {
                if (response.status === 200) {
                    if (response.data.message === "Success, We sent you a link to reset your password via E-mail") {
                        let message = userLang.startsWith('no') || userLang.startsWith('nb') ? `Vellykket, vi sendte deg en lenke for å tilbakestille passordet ditt via e-post` : `Success, We sent you a link to reset your password via E-mail`
                        toast.success(message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: 0,
                            toastId: "my_toast",
                        });
                    }else{
                        let message = userLang.startsWith('no') || userLang.startsWith('nb') ? `Tilbakestill passord mislyktes. Prøv igjen` : `Reset Password fail, Please try again`
                        toast.error(message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: 0,
                            toastId: "my_toast",
                        });
                    }
                }
            })
    };

    return (
        <>
            <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
                <div className="w-full p-6 m-auto rounded-md shadow-md bg-main_bg lg:max-w-xl">
                    <h1 className="text-3xl font-semibold text-center text-navbar_color">
                        {userLang.startsWith('no') || userLang.startsWith('nb') ? `Glemt passord` : `Forgot Password`}
                    </h1>
                    <form className="mt-6" autoComplete="off" onSubmit={handleSubmit(getLink)}>
                        <div className="mb-2">
                            <label
                                htmlFor="email"
                                className="block text-sm font-semibold text-gray-800"
                            >
                                {userLang.startsWith('no') || userLang.startsWith('nb') ? `E-post` : `Email`}
                            </label>
                            <input
                                type="email"
                                className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                                {...register("email", { required: userLang.startsWith('no') || userLang.startsWith('nb') ? `E-post er påkrevd!` : `Email is required!` })}
                            />
                            {errors.email && (
                                <p className="text-danger" style={{ fontSize: 14 }}>
                                    {errors.email.message}
                                </p>
                            )}
                        </div>
                        <div className="mt-6">
                            <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform rounded-md bg-navbar_color hover:bg-navbar_color focus:outline-none focus:bg-navbar_color">
                                {userLang.startsWith('no') || userLang.startsWith('nb') ? `Få Reset Link` : `Get Reset Link`}
                            </button>
                        </div>
                    </form>
                    <p className="mt-8 text-xs font-light text-center text-gray-700">
                        {userLang.startsWith('no') || userLang.startsWith('nb') ? `Glem ikke!` : `Never mind!`}
                        <Link to={"/login"}
                            href="#"
                            className="font-medium text-navbar_color hover:underline"
                        >
                            {userLang.startsWith('no') || userLang.startsWith('nb') ? `Ta meg tilbake til Logg inn` : `Take me back to Login`}
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
};
export default ReSetPassword;
