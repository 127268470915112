import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
const { default: axios } = require('axios');
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { RouteComponentProps } from "react-router";

type SomeComponentProps = RouteComponentProps;
const VerifyCode: FC<SomeComponentProps> = ({ history }): JSX.Element => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isRequestingCode, setIsRequestingCode] = useState(true)
  const [email, setEmail] = useState<any>(null);
  const [userLang, setUserLang] = useState<string>(navigator.language);
  const requestCode = (data: any) => {
    let params = {
      email: data.email,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "api/requestCode", params)
      .then(function (response: any) {
        console.log(response);
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
          reset();
          setIsRequestingCode(false)
          setEmail(params.email)
        }
      })
      .catch(function (error: any) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      });

  }
  const verifyCode = (data: any) => {
    let params = {
      email: email,
      verifyCode: data.code,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "api/verifyCode", params)
      .then(function (response: any) {
        console.log(response);
        if (response.status === 200) {
          reset();
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
          setTimeout(() => {
            history.push("/login");
          }, 1000);
        }
      })
      .catch(function (error: any) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      });
  };

  return (
    <>
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
        <div className="w-full p-6 m-auto rounded-md shadow-md bg-main_bg lg:max-w-xl">
          {
            isRequestingCode ? <>
              <h1 className="text-3xl font-semibold text-center text-navbar_color">
                {userLang.startsWith('no') || userLang.startsWith('nb')? `Send bekreftelseskode` : `Send Verification Code` }
              </h1>
              <form className="mt-6" autoComplete="off" onSubmit={handleSubmit(requestCode)}>
                <div className="mb-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold text-gray-800"
                  >
                    {userLang.startsWith('no') || userLang.startsWith('nb')? `E-post` : `Email` }

                  </label>
                  <input
                    type="email"
                    className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                    {...register("email", { required: "Email is required!" })}
                  />
                  {errors.email && (
                    <p className="text-danger" style={{ fontSize: 14 }}>
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div className="mt-6">
                  <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform rounded-md bg-navbar_color hover:bg-navbar_color focus:outline-none focus:bg-navbar_color">
                    {userLang.startsWith('no') || userLang.startsWith('nb')? `Send kode` : `Send Code` }
                  </button>
                </div>
              </form>
            </> :
              <>
                <h1 className="text-3xl font-semibold text-center text-navbar_color">
                  {userLang.startsWith('no') || userLang.startsWith('nb')? `Verifiser kode` : `Verify Code` }
                </h1>
                <form className="mt-6" autoComplete="off" onSubmit={handleSubmit(verifyCode)}>
                  <div className="mb-2">
                    <label
                      htmlFor="password"
                      className="block text-sm font-semibold text-gray-800"
                    >
                    {userLang.startsWith('no') || userLang.startsWith('nb')? `kode` : `Code` }
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                      {...register("code", {
                        required: "Code is required!",
                      })}
                    />
                    {errors.code && (
                      <p className="text-danger" style={{ fontSize: 14 }}>
                        {errors.code.message}
                      </p>
                    )}
                  </div>
                  <div className="mt-6">
                    <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform rounded-md bg-navbar_color hover:bg-navbar_color focus:outline-none focus:bg-navbar_color">
                      {userLang.startsWith('no') || userLang.startsWith('nb')? `Verifiser` : `Verify` }
                    </button>
                  </div>
                </form>
              </>
          }
          <p className="mt-8 text-xs font-light text-center text-gray-700">
            {" "}
            {userLang.startsWith('no') || userLang.startsWith('nb')? `Har du allerede en konto?` : `Already have an account?` }{" "}
            <Link to={"/login"}
              href="#"
              className="font-medium text-navbar_color hover:underline"
            >
              {userLang.startsWith('no') || userLang.startsWith('nb')? `Logg inn` : `Sign In` }
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};
export default VerifyCode;
