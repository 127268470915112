import React, { useState, useEffect } from 'react';
import '../App.css'; // Import your custom styles if needed
const { default: axios } = require('axios');
import { toast } from "react-toastify";
interface CompanySignUpProps {
    setValidating: any
    email: any
    history: any
    setRegisterd: any
}
const CompanySignUp: React.FC<CompanySignUpProps> = ({ setValidating, email, history, setRegisterd}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [companyType, setCompanyType] = useState<string>('norwegian');
    const [companyName, setCompanyName] = useState<string>('');
    const [companyId, setCompanyId] = useState<string>('');
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [result, setResult] = useState<string | null>("");
    const [userLang, setUserLang] = useState<string>(navigator.language);

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(e.target.value)
        debounceFetchSuggestions(e.target.value);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 100);
    };

    const toggleFields = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyType(e.target.value);
        console.log(e.target.value)
    };

    const debounceFetchSuggestions = (filter) => {
        setTimeout(() => {
            fetchSuggestions(filter)
        }, 50);
    };

    const fetchSuggestions = async (filter) => {
        if (filter.length < 2) {
            setSuggestions([]);
            return;
        }
        const response = await fetch(process.env.REACT_APP_API_URL + `api/suggestions?query=${filter}`);
        const data = await response.json();
        setSuggestions(data);
    };

    const registerCompany = async () => {
        const data: any = new FormData();
        data.append('companyName', companyName)
        data.append('companyId', companyId)
        data.append('email', email)
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/registerCompany", data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200) {
            if (result.data.message == "Company registeration successed") {
                const successMessage = userLang.startsWith('no') || userLang.startsWith('nb') ? "Bedriftsregistreringen er fullført!" : "Company registeration successed!";
                toast.success(successMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                setTimeout(() => {
                    history.push("/login");
                }, 1000);
            }
            if (result.data.message == "Already exists") {
                const existsMessage = userLang.startsWith('no') || userLang.startsWith('nb') ? "Kontoen eksisterer, kontakt systemadministratoren din for å invitere deg" : "Account exists, contact your system administrator to invite you";
                toast.warning(existsMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
            }
        }
        else {
            const errorMessage = userLang.startsWith('no') || userLang.startsWith('nb')
                ? 'Firmaregistrering mislyktes'
                : 'Company registeration failed';
            setResult(`<div class="text-red-500">${errorMessage}</div>`);
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="w-1/3 p-8 bg-white rounded shadow-md">
                <h1 id="title" className="mb-6 text-2xl font-bold">{userLang.startsWith('no') || userLang.startsWith('nb') ? <>Finn din bedrift</> : <>Find your company</>}</h1>
                <div className="mb-6">
                    <div className="flex flex-col space-y-2">
                        <label className="inline-flex items-center">
                            <input type="radio" className="form-radio" name="companyType" value="norwegian" checked={companyType === 'norwegian'} onChange={toggleFields} />
                            <span id="norwegian-label" className="ml-2">{userLang.startsWith('no') || userLang.startsWith('nb') ? <>Norsk bedrift</> : <>Norwegian company</>}</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input type="radio" className="form-radio" name="companyType" value="international" checked={companyType === 'international'} onChange={toggleFields} />
                            <span id="international-label" className="ml-2">{userLang.startsWith('no') || userLang.startsWith('nb') ? <>Internasjonal bedrift</> : <>International Company</>}</span>
                        </label>
                    </div>
                </div>

                <form id="search-form" className={companyType == 'norwegian' ? 'relative' : 'hidden'}>
                    <input
                        type="text"
                        id="query"
                        name="query"
                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                        placeholder={`${userLang.startsWith('no') || userLang.startsWith('nb') ? "Skriv inn organisasjonsnummer eller firmanavn" : "Enter organization number or company name"}`}
                        required
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleFilterChange}
                        value={companyName}
                    />
                    {suggestions.length > 0 && (
                        <div id="suggestions" className="absolute z-10 w-full bg-white top-11 suggestions">
                            {suggestions.map((suggestion, index) => (
                                <div key={index} className="suggestion-item" onClick={() => {
                                    setCompanyName(suggestion?.name)
                                    setCompanyId(suggestion?.id)
                                    setSuggestions([]);
                                }}>
                                    {suggestion?.name}
                                </div>
                            ))}
                        </div>
                    )}
                    <button onClick={(e) => {
                        e.preventDefault()
                        registerCompany()
                    }} className="w-full p-2 text-white rounded bg-custom">{userLang.startsWith('no') || userLang.startsWith('nb') ? <>Neste</> : <>Next</>}</button>
                </form>

                <form id="save-form" className={companyType === 'international' ? '' : 'hidden'}>
                    <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                        placeholder={`${userLang.startsWith('no') || userLang.startsWith('nb') ? "Skriv inn firmanavn" : "Enter company name"}`}
                        required
                        onChange={(e) => {
                            setCompanyName(e.target.value)
                        }}
                        value={companyName}
                    />
                    <button type="button" className="w-full p-2 text-white rounded bg-custom" onClick={(e) => {
                        e.preventDefault()
                        registerCompany()
                    }}>{userLang.startsWith('no') || userLang.startsWith('nb') ? <>Neste</> : <>Next</>}</button>
                </form>

                <div id="result" className="mt-6 text-center" dangerouslySetInnerHTML={{ __html: result || '' }}></div>
            </div>
        </div>
    );
};

export default CompanySignUp;
