import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
const { default: axios } = require('axios');
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { RouteComponentProps } from "react-router";
import { useLocation } from 'react-router-dom';

type SomeComponentProps = RouteComponentProps;
const ReSetPassword: FC<SomeComponentProps> = ({ history }): JSX.Element => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const location = useLocation();
    const [email, setEmail] = useState("")
    const [verifyCode, setVerifyCode] = useState("verifyCode")
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('email');
        const verifyCode = searchParams.get('verifyCode');
        if (email && verifyCode) {
            setEmail(email)
            setVerifyCode(verifyCode)
        }
    }, [location.search]);

    const setPassword = (data: any) => {
        let params = {
            email: email,
            verifyCode: verifyCode,
            password: data.password,
        };
        axios
            .post(process.env.REACT_APP_API_URL + "api/resetPassword", params)
            .then(function (response: any) {
                console.log(response);
                if (response.status === 200) {
                    reset();
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                    setTimeout(() => {
                        history.push("/login");
                    }, 1000);
                }
            })
            .catch(function (error: any) {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
            });
    };

    return (
        <>
            <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
                <div className="w-full p-6 m-auto rounded-md shadow-md bg-main_bg lg:max-w-xl">
                    <h1 className="text-3xl font-semibold text-center text-navbar_color">
                        Set Password
                    </h1>
                    <form className="mt-6" autoComplete="off" onSubmit={handleSubmit(setPassword)}>
                        <div className="mb-2">
                            <label
                                htmlFor="password"
                                className="block text-sm font-semibold text-gray-800"
                            >
                                Password
                            </label>
                            <input
                                type="password"
                                className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                                {...register("password", {
                                    required: "Password is required!",
                                    minLength: {
                                        value: 6,
                                        message: 'Password should be at least 6 characters long',
                                    },
                                    pattern: {
                                        value: /^(?=.{6,})/,
                                        message:
                                            "Password should contain at least one number and one symbol",
                                    },
                                })}
                            />
                            {errors.password && (
                                <p className="text-danger" style={{ fontSize: 14 }}>
                                    {errors.password.message}
                                </p>
                            )}
                        </div>
                        <div className="mt-6">
                            <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform rounded-md bg-navbar_color hover:bg-navbar_color focus:outline-none focus:bg-navbar_color">
                                Set Password
                            </button>
                        </div>
                    </form>
                    <p className="mt-8 text-xs font-light text-center text-gray-700">
                        {" "}
                        Already have an account?{" "}
                        <Link to={"/login"}
                            href="#"
                            className="font-medium text-navbar_color hover:underline"
                        >
                            Sign In
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
};
export default ReSetPassword;
