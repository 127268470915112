import { ReactElement } from 'react'
import { useState, useEffect } from 'react';
const { default: axios } = require('axios');
import { parseJWT } from '../../../utils';
import { ToastContainer, toast, Flip } from "react-toastify";
import { Chat } from "./Chat";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import { ReactComponent as ResizeIcon } from "../../../assets/svg/resize.svg";
import { ReactComponent as TrashIcon } from "../../../assets/svg/trash.svg";
import { useTranslation } from 'react-i18next';

interface Message {
    isUserMessage: boolean;
    text: string;
    id: string;
    documents: string[],
    streaming: boolean,
}

interface ChatComponentProps {
    documentClick: any
    styleRightSide: any
    openedDocument: string,
    setDocumentUpdated: any,
    isChatWindowClosed: boolean,
    setChatWindowClosed: any,
    promptList: any,
    promptDataFetch: any,
    selectedDocumentList: any[]
    selectedTab: string
    previousOpenedDocument: string
    selectedFolderName: string
    selectedFolderID: string
    isFolderContainFulldocuments: boolean
}

export const ChatComponent: React.FC<ChatComponentProps> = ({documentClick, styleRightSide, openedDocument, setDocumentUpdated, isChatWindowClosed, setChatWindowClosed, promptList, promptDataFetch, selectedDocumentList, selectedTab, previousOpenedDocument, selectedFolderName, isFolderContainFulldocuments, selectedFolderID}) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [promptTitle, setPromptTitle] = useState("");
    const [promptText, setPromptText] = useState("");
    const [promptId, setPromptId] = useState("");
    const [modalAction, setModalAction] = useState("Creation");
    const [selectedPrompt, setSelectedPrompt] = useState("");
    const [isSmallChatWindowOpen, setSmallChatWindowOpen] = useState(false);
    const { t } = useTranslation();
    let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;

    const handlePlusButtonClick = () => {
        setShowModal(true);
        setModalAction("Creation");
        setPromptTitle("");
        setPromptText("");
        setPromptId("");
    }

    const handleEditPrompt = async (prompt: any) => {
        setShowModal(true);
        setModalAction("Edition");
        setPromptTitle(prompt.title);
        setPromptText(prompt.text);
        setPromptId(prompt.id);
    }

    const handleDelete = async () => {
        try {
            if (promptId) {
                const data = new FormData();
                data.append("id", promptId);
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/deletePrompt", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    toast.success(t("Successfully Deleted!"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                    promptDataFetch();
                }
                setShowModal(false);
                setPromptTitle("");
                setPromptText("");
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchChatHistory = async () => {
        try {
            const data = {
                "email": user?.sub?.email
            };
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getChatHistory", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            let newItems: Message[] = [];
            if (result.data.data.length > 0) {
                for (const item of result.data.data) {
                    const UserMessage: Message = {
                        isUserMessage: true,
                        text: item?.user_message,
                        id: item?.user_message_id,
                        documents: [],
                        streaming: false
                    };
                    const AIMessage: Message = {
                        isUserMessage: false,
                        text: item?.ai_message,
                        id: item?.ai_message_id,
                        documents: item?.source,
                        streaming: false
                    }
                    newItems.push(...[UserMessage, AIMessage]);

                }
            }
            setMessages(newItems);
        } catch (error) {
            console.log(error)
        }
    }

    const handleSave = async () => {
        try {
            setShowModal(true);
            if (modalAction === "Creation") {
                const data = new FormData();
                data.append("email", user.sub.email);
                data.append("title", promptTitle);
                data.append("text", promptText);
                data.append('type', 'chat');
                data.append('selectedFolderID', "");
                data.append('isGlobalInsight', "");
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/createPrompt", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    toast.success(t("Successfully Created!"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                    promptDataFetch();
                }
            } else if (modalAction === "Edition" && promptId !== "") {
                const data = new FormData();
                data.append("id", promptId);
                data.append("title", promptTitle);
                data.append("text", promptText);
                data.append('type', 'chat');
                data.append('selectedFolderID', "");
                data.append('isGlobalInsight', "");
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/updatePrompt", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    toast.success(t("Successfully Edited!"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                    promptDataFetch();
                }
            }
            setShowModal(false);
            setPromptTitle("");
            setPromptText("");
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectPrompt = (prompt: any) => {
        setSelectedPrompt(prompt);
    }

    const handleDocumentClick = (document: string) => {
        documentClick(document)
    }

    const handleCloseIconClick = () => {
        setChatWindowClosed(true)
    }

    const handleResizeClick = () => {

    }

    const clearChatHistory = async () => {
        if (confirm("Are you sure to delete chat history?")) {
            const data = new FormData();
            data.append("email", user?.sub?.email);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/deleteChatHistory", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            fetchChatHistory();
        }
    }

    return (
        <div className={`chatbox md:w-full lg:w-[33.334%] lg:min-w-[28%] text-center bg-bg_body rounded-xl ${isChatWindowClosed || isSmallChatWindowOpen ? "hidechat" : ""}`} style={styleRightSide}>
            <div className='relative'>
                <button className='absolute right-[1%] top-[10px]' onClick={handleCloseIconClick}>
                    <CloseIcon className='text-black h-9 w-9'></CloseIcon>
                </button>
                <button className='absolute right-[0] top-[10px] mr-[40px]' onClick={handleResizeClick}>
                    <ResizeIcon className='text-black h-9 w-9'></ResizeIcon>
                </button>
                <button className='absolute right-[35px] top-[10px] mr-[40px]' onClick={clearChatHistory} title={t("Clear Chat History")}>
                    <svg className="h-8 w-8 text-gray-700 mt-[2px]" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                </button>
            </div>
            <ul className="flex text-sm font-medium text-center text-gray-500 dark:text-gray-400 p-[10px] overflow-x-scroll" style={{ width: "calc(100% - 80px)" }}>
                {promptList.map((prompt: any, index: number) => {
                    if (prompt.type === 'chat') {
                        return (
                            <button
                                key={index}
                                className="flex items-center flex-row pl-[10px] pr-[10px] text-white rounded mr-2 bg-cyan-700 active:bg-[#8b4000]"
                            >
                                <svg
                                    onClick={() => handleEditPrompt(prompt)}
                                    className="w-4 h-4"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                                </svg>
                                <span onClick={() => handleSelectPrompt(prompt)} className="ml-[8px]">
                                    {prompt.title}
                                </span>
                            </button>
                        );
                    }
                    return null;
                })}
                <button onClick={() => handlePlusButtonClick()}>
                    <svg className="h-6 w-6 text-black m-[5px] mr-[10px]" width="14" height="14" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1="12" y1="5" x2="12" y2="19" />  <line x1="5" y1="12" x2="19" y2="12" /></svg>
                </button>

            </ul>
            <div className="mb-6">
                <Chat selectedFolderID={selectedFolderID} selectedPrompt={selectedPrompt} handleDocumentClick={handleDocumentClick} isChatWindowClosed={isChatWindowClosed} setChatWindow={(val: boolean) => setChatWindowClosed(val)} openedDocument={openedDocument} setDocumentUpdated={(val: boolean) => setDocumentUpdated(val)} isSmallChatWindowOpen={isSmallChatWindowOpen} setSmallChatWindowOpen={(val: boolean) => setSmallChatWindowOpen(val)} selectedDocumentList={selectedDocumentList} selectedTab={selectedTab} previousOpenedDocument={previousOpenedDocument} selectedFolderName={selectedFolderName} isFolderContainFulldocuments={isFolderContainFulldocuments} fetchChatHistory={fetchChatHistory} messages={messages} setMessages={setMessages}></Chat>
            </div>
            {showModal ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                    >
                        <div className="relative w-[360px] my-6 mx-auto md:w-[50%]">
                            <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none bg-main_bg focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                                    <h3 className="text-3xl font-semibold text-black">
                                        {t("Template prompt")}
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-black outline-none opacity-5 focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto p-6">
                                    <input type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-3" placeholder={t("Prompt Title")} required onChange={(e) => setPromptTitle(e.target
                                        .value)} value={promptTitle} />

                                    <textarea id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Write your prompts here...")} onChange={(e) => setPromptText(e.target.value)} value={promptText}></textarea>

                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                                    <button
                                        className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        {t("Close")}
                                    </button>
                                    {
                                        modalAction !== "Creation" ?
                                            <button
                                                className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-700 rounded shadow outline-none active:bg-red-500 hover:shadow-lg focus:outline-none"
                                                type="button"
                                                onClick={() => handleDelete()}
                                            >
                                                {t("Delete")}
                                            </button> : <></>
                                    }
                                    <button
                                        className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                        type="button"
                                        onClick={() => handleSave()}
                                    >
                                        {t("Save Changes")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </>
            ) : null}
        </div>
    )
}