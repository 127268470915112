import { inArray } from 'jquery'
import React from 'react'

interface UserPageProps {
    userData: any,
    handleEditClick: any
    companyName: any
    ownerUserId: any
    handleDeleteClick: any
    licenseCompany: any
    status: any
}
export const UserPage: React.FC<UserPageProps> = ({ userData, handleEditClick, companyName, ownerUserId, handleDeleteClick, licenseCompany, status }) => {
    return (
        "email" in userData[0] && userData?.map((user, index: number) => {
            return <tr className="bg-white border-b" key={index}>
                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                    {user?.name}
                </th>
                <td className="px-6 py-4 text-neutral-500">
                    {user?.email}
                </td>
                <td className="px-6 py-4 text-neutral-500">
                    {user?.role ? user?.role : "Member"}
                </td>
                <td className="px-6 py-4 text-neutral-500">
                    {user?.isVerified ? "Verified" : "Pending"}
                </td>
                <td className="px-6 py-4 text-neutral-500">
                </td>
                {status == "deleted" ? <td className='float-right px-6 py-4 ml-[20px] font-medium text-red-600'>Disabled</td> : <td className="float-right px-6 py-4">
                    <button className="font-medium text-blue-600 hover:underline" onClick={() => handleEditClick(user, companyName, ownerUserId)}>Edit</button>
                    <button className="ml-[20px] font-medium text-red-600 " onClick={() => handleDeleteClick(user)}>Delete</button>
                </td>}
            </tr>
        })
    )
}