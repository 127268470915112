import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { RouteComponentProps } from "react-router";
import i18n from '../i18n';
import { io } from 'socket.io-client';
import { data } from 'jquery';
import CustomToast from "./Main/CustomToast";
import { useTranslation } from 'react-i18next';
import userEvent from "@testing-library/user-event";
const { default: axios } = require('axios');
type SomeComponentProps = RouteComponentProps;
const socketId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

const Socket = io(`${process.env.REACT_APP_API_URL}`,{
  query: { socketId: socketId }
});
const Login: FC<SomeComponentProps> = ({ history }): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  const [email, setEmail] = useState<any>(null);
  const [tender_id, setTenderId] = useState<any>(null);
  console.log(email, "-", tender_id)
  const [currentStep, setCurrentStep] = useState(1);
  const [register_email, setRegisterEmail] = useState<any>(localStorage.getItem("register_email")?localStorage.getItem("register_email"):"");
  const [userLang, setUserLang] = useState<string>(navigator.language);
  const [toastId, setToastId] = useState<any>(null);
  const addToWorkSpace = async (email: any, tender_id: any) => {
    let isValid = await checkAddtoWorkspace(email, tender_id);
    if (isValid) {
      if (toastId) {
        toast.dismiss(toastId);
      }
      const id = toast(
        <CustomToast currentStep={1} />,
        {
          autoClose: false, // Prevent auto-close to control it manually
          closeOnClick: true,
          draggable: false,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: 0
        }
      );
      setToastId(id);
      Socket.on('status_update', (message: { status: number }) => {
        setTimeout(() => {
          toast.update(id, {
            render: <CustomToast currentStep={message.status} />
          });
        }, 100)
      });
      try {
        const result = await axios.get(process.env.REACT_APP_API_URL + "api/add_to_workspace?email=" + email + "&tender_id=" + tender_id + "&socketId=" + socketId, {
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (result.status === 200) {
          setCurrentStep(1)
          setTimeout(() => {
            toast.dismiss(id);
          }, 2000)
          setEmail(null)
          setTenderId(null)
        } else {
          setCurrentStep(1)
          setTimeout(() => {
            toast.dismiss(id);
          }, 2000)
          setEmail(null)
          setTenderId(null)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  const checkAddtoWorkspace = async (email: any, tender_id: any) => {
    try {
      const result = await axios.get(process.env.REACT_APP_API_URL + "api/check_addedtoworkspace?email=" + email + "&tender_id=" + tender_id, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (result.status === 200) {
        if (result.data.message == 'Already Added to WorkSpace'){
          let notification = userLang.startsWith('no') || userLang.startsWith('nb')? `Allerede lagt til WorkSpace` : `Already Added to WorkSpace`
          toast.warning(notification, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
        }
        setEmail(null)
        setTenderId(null)
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error)
      return true;
    }
  }
  useEffect(() => {
    if (email && tender_id) {
      addToWorkSpace(email, tender_id)
    }
  }, [email, tender_id])
  useEffect(() => {
    setEmail(localStorage.getItem("email"))
    setTenderId(localStorage.getItem("tender_id"))
  }, [])
  const login = (data: any) => {
    let params = {
      email: data.email,
      password: data.password
    };
    axios
      .post(process.env.REACT_APP_API_URL + "api/login", params)
      .then(function (response: any) {
        console.log(response);
        if (response.status === 200) {
          var message = ""
          if(response.data.message == "User Logined Successfully"){
            message = userLang.startsWith('no') || userLang.startsWith('nb')? `Bruker logget på vellykket` : `User Logined Successfully`
          }
          toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
          localStorage.setItem("tender_auth", response.data.token);
          setTimeout(() => {
            i18n.changeLanguage("no");
            history.push("/");
          }, 1000);
        }
      })
      .catch(function (error: any) {
        var message = ""
        if(error.response.data.message == "User is not verified"){
          message = userLang.startsWith('no') || userLang.startsWith('nb')? `Brukeren er ikke bekreftet` : `User is not verified`
        }
        if(error.response.data.message == "Login Failed"){
          message = userLang.startsWith('no') || userLang.startsWith('nb')? `Innlogging feilet` : `Login Failed`
        }
        if(error.response.data.message == "This user doesn't exists"){
          message = userLang.startsWith('no') || userLang.startsWith('nb')? `Denne brukeren eksisterer ikke` : `This user doesn't exists`
        }
        if(error.response.data.message == "Password is wrong"){
          message = userLang.startsWith('no') || userLang.startsWith('nb')? `Passordet er feil` : `Password is wrong`
        }
        if(error.response.data.message == "Company account is disabled"){
          message = userLang.startsWith('no') || userLang.startsWith('nb')? `Firmakontoen er deaktivert` : `Company account is disabled`
        }
        toast.error(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      });
  };
  return (
    <>
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
        <div className="w-full p-6 m-auto rounded-md shadow-md bg-main_bg lg:max-w-xl">
          <h1 className="text-3xl font-semibold text-center text-navbar_color">
            {userLang.startsWith('no') || userLang.startsWith('nb')? `Logg inn` : `Sign In` }
          </h1>
          <form className="mt-6" autoComplete="off" onSubmit={handleSubmit(login)}>
            <div className="mb-2">
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-gray-800"
              >
                {userLang.startsWith('no') || userLang.startsWith('nb')? `E-post`: `Email`}
              </label>
              <input
                type="email"
                defaultValue={register_email}
                className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                {...register("email", { required: userLang.startsWith('no') || userLang.startsWith('nb')? `E-post er påkrevd!` : `Email is required!` ,})}
              />
              {errors.email && (
                <p className="text-danger" style={{ fontSize: 14 }}>
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className="mb-2">
              <label
                htmlFor="password"
                className="block text-sm font-semibold text-gray-800"
              >
                {userLang.startsWith('no') || userLang.startsWith('nb')? `Passord`: `Password`}
              </label>
              <input
                type="password"
                className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                {...register("password", {
                  required: userLang.startsWith('no') || userLang.startsWith('nb')? `Passord er påkrevd!` : `Password is required!`
                })}
              />
              {errors.password && (
                <p className="text-danger" style={{ fontSize: 14 }}>
                  {errors.password.message}
                </p>
              )}
            </div>
            <div className="mt-6">
              <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform rounded-md bg-navbar_color hover:bg-navbar_color focus:outline-none focus:bg-navbar_color">
                {userLang.startsWith('no') || userLang.startsWith('nb')? `Logg inn` : `Login` }
              </button>
            </div>
          </form>
          <div className="mt-4 text-xs font-light text-center text-gray-700 underline">
            <Link to={"/ForgotPassword"} >
              {userLang.startsWith('no') || userLang.startsWith('nb')? `Glemt passord?` : `Forgot Password?` }
            </Link>
          </div>

          {/* <p className="mt-8 text-xs font-light text-center text-gray-700">
            {" "}
            Don't have an account?{" "}
            <Link to={"/register"}
              href="#"
              className="font-medium text-navbar_color hover:underline"
            >
              Sign up
            </Link>
          </p> */}

          <p className="mt-4 text-xs font-light text-center text-gray-700">
            {" "}
              {userLang.startsWith('no') || userLang.startsWith('nb')? `Har du ikke bekreftet en konto` : `Didn't verify an account?`}
            {" "}
            <Link to={"/verifycode"}
              href="#"
              className="font-medium text-navbar_color hover:underline"
            >
            {userLang.startsWith('no') || userLang.startsWith('nb')? `Bekrefte` : `Verify` }
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};
export default Login;
