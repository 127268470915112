import React, { useState, useEffect, Children } from 'react';
import TreeItem from './TreeItem';

interface TreeProps {
    data: TreeData[];
    checkedItems: string[]
    setCheckedItems: any
}

interface TreeData {
    id: string;
    label: string;
    children?: TreeData[];
}
const LocationTree: React.FC<TreeProps> = ({ data, checkedItems, setCheckedItems }) => {
    const handleCheckboxChange = (id: string, isChecked: boolean) => {
        let newCheckedIds: string[] = [...checkedItems];

        if (isChecked) {
            newCheckedIds.push(id);
        } else {
            newCheckedIds = newCheckedIds.filter((checkedId) => checkedId !== id);
        }

        setCheckedItems(newCheckedIds);
    };



    return (
        <div>
            {data.map((item, index) => (
                <TreeItem
                    key={item.id + index}
                    id={item.id}
                    label={item.label}
                    isChecked={checkedItems.includes(item.id)}
                    onCheckboxChange={(isChecked) => handleCheckboxChange(item.id, isChecked)}
                >
                    {item.children && item.children.length > 0 && <LocationTree data={item.children} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />}
                </TreeItem>
            ))}
        </div>
    );
};

export default LocationTree;