import React, { FC, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { useForm } from "react-hook-form";
const { default: axios } = require('axios');
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import CompanySignUp from "./CompanySignUp";

type SomeComponentProps = RouteComponentProps;
/**
 * Renders a sign-up form and handles form submission.
 *
 * @param {SomeComponentProps} props - The props object containing the history prop.
 * @return {JSX.Element} The sign-up form component.
 */
const SignUp: FC<SomeComponentProps> = ({ history }) => {
  useEffect(() => {
  }, [])
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [isValidating, setValidating] = useState(false);
  const [isRegisterd, setRegisterd] = useState(false);
  const [email, setEmail] = useState<any>(null);
  const [userLang, setUserLang] = useState<string>(navigator.language);
  /**
   * Submits the sign-up data to the server.
   *
   * @param {Object} data - The sign-up data containing name, companyName, email, and password.
   * @return {void}
   */
  const submitData = (data: any) => {
    let params = {
      name: data.name,
      // companyName: data.companyName,
      email: data.email,
      password: data.password,
    };
    setEmail(params.email)
    localStorage.setItem("register_email", params.email)
    axios
      .post(process.env.REACT_APP_API_URL + "api/signup", params)
      .then(function (response: any) {
        var message = ""
        if (response.data.message == "User created successfully. please enter the verification code sent to your email") {
          message = userLang.startsWith('no') || userLang.startsWith('nb') ? `Bruker opprettet. vennligst skriv inn bekreftelseskoden som ble sendt til e-posten din` : `User created successfully. please enter the verification code sent to your email`
        }
        if (response.data.message == "Username already exists") {
          message = userLang.startsWith('no') || userLang.startsWith('nb') ? `Brukernavn finnes allerede` : `Username already exists`
          toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
          return false
        }
        if (response.data.message == "Failed to sign up") {
          message = userLang.startsWith('no') || userLang.startsWith('nb') ? `Kunne ikke registrere deg` : `Failed to sign up`
          toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
          return false
        }
        toast.success(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
        reset();
        setValidating(true)
        localStorage.setitem("email", params.email);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };

  /**
   * Verifies the sign-up code sent to the email address.
   *
   * @param {Object} data - The data containing the sign-up code.
   * @return {void}
   */
  const verifyCode = (data: any) => {
    let params = {
      email: email,
      verifyCode: data.verifyCode
    }
    axios
      .post(process.env.REACT_APP_API_URL + "api/verifyCode", params)
      .then(function (response: any) {
        var message = ""
        if (response.data.message == "User verified successfully") {
          message = userLang.startsWith('no') || userLang.startsWith('nb') ? `Bruker bekreftet vellykket` : `User verified successfully`
        }
        toast.success(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
        reset();
        setValidating(false)
        setRegisterd(true)
      })
      .catch(function (error: any) {
        var message = ""
        if (error.response.data.message == "Verify failed") {
          message = userLang.startsWith('no') || userLang.startsWith('nb') ? `Bruker bekreftet vellykket` : `Verify failed`
        }
        if (error.response.data.message == "Verify failed") {
          message = userLang.startsWith('no') || userLang.startsWith('nb') ? `Bruker finnes ikke` : `User does not exist`
        }
        if (error.response.data.message == "Verify failed") {
          message = userLang.startsWith('no') || userLang.startsWith('nb') ? `Bekreft koden mislyktes` : `Verify code failed`
        }
        toast.error(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      });
  }
  return (
    <>
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
        {
          isValidating ?
            <div className="w-full p-6 m-auto rounded-md shadow-md bg-main_bg lg:max-w-xl">
              <form className="mt-6" autoComplete="off" onSubmit={handleSubmit(verifyCode)}>
                <div className="mb-2">
                  <label
                    htmlFor="verifyCode"
                    className="block text-sm font-semibold text-gray-800"
                  >
                    {userLang.startsWith('no') || userLang.startsWith('nb') ? `Kode` : `Code`}
                  </label>
                  <input
                    type="text"
                    className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                    {...register("verifyCode", {
                      required: "Code is required",
                    })}
                  />
                  {errors.verifyCode && (
                    <p className="text-danger" style={{ fontSize: 14 }}>
                      {errors.verifyCode.message}
                    </p>
                  )}
                </div>
                <div className="mt-6">
                  <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform rounded-md bg-navbar_color">
                    {userLang.startsWith('no') || userLang.startsWith('nb') ? `Verifiser kode` : `Verify Code`}
                  </button>
                </div>
              </form>
            </div>
            :
            isRegisterd ? <CompanySignUp history={history} setValidating={setValidating} email={email} setRegisterd={setRegisterd}></CompanySignUp> :
              <div className="w-full p-6 m-auto rounded-md shadow-md bg-main_bg lg:max-w-xl">
                <h1 className="text-3xl font-semibold text-center text-navbar_color">
                  {userLang.startsWith('no') || userLang.startsWith('nb') ? `Lag bruker` : `Sign Up`}
                </h1>
                <form className="mt-6" autoComplete="off" onSubmit={handleSubmit(submitData)}>
                  <div className="mb-2">
                    <label
                      htmlFor="Name"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      {userLang.startsWith('no') || userLang.startsWith('nb') ? `Navn` : `Name`}
                    </label>
                    <input
                      type="Name"
                      className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                      {...register("name", {
                        required: userLang.startsWith('no') || userLang.startsWith('nb') ? `Navn er påkrevd!` : `Name is required!`,
                      })}
                    />
                    {errors.name && (
                      <p className="text-danger" style={{ fontSize: 14 }}>
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                  {/* <div className="mb-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                      {...register("companyName", { required: "Company Name is required!" })}
                    />
                    {errors.companyName && (
                      <p className="text-danger" style={{ fontSize: 14 }}>
                        {errors.companyName.message}
                      </p>
                    )}
                  </div> */}
                  <div className="mb-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      {userLang.startsWith('no') || userLang.startsWith('nb') ? `E-post` : `Email`}
                    </label>
                    <input
                      type="email"
                      className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                      {...register("email", {
                        required: userLang.startsWith('no') || userLang.startsWith('nb')
                          ? 'E-post er påkrevd!'
                          : 'Email is required!'
                      })}
                    />
                    {errors.email && (
                      <p className="text-danger" style={{ fontSize: 14 }}>
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="password"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      {userLang.startsWith('no') || userLang.startsWith('nb') ? `Passord` : `Passord`}
                    </label>
                    <input
                      type="password"
                      className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                      {...register("password", {
                        required: userLang.startsWith('no') || userLang.startsWith('nb') ? `passord er påkrevd!` : `Password is required!`,
                        minLength: {
                          value: 6,
                          message: 'Password should be at least 6 characters long',
                        }
                      })}
                    />
                    {errors.password && (
                      <p className="text-danger" style={{ fontSize: 14 }}>
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="password"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      {userLang.startsWith('no') || userLang.startsWith('nb') ? `Bekreft passord` : `Confirm Password`}
                    </label>
                    <input
                      type="password"
                      className="block w-full px-4 py-2 mt-2 border rounded-md text-navbar_color bg-main_bg focus:border-navbar_color focus:ring-navbar_color focus:outline-none focus:ring focus:ring-opacity-40"
                      {...register("cpassword", {
                        required: userLang.startsWith('no') || userLang.startsWith('nb') ? `Bekreft passord er nødvendig!` : `Confirm Password is required!`,
                        validate: (value) =>
                          value === watch("password") ||
                          "Passwords don't match.",
                      })}
                    />
                    {errors.cpassword && (
                      <p className="text-danger" style={{ fontSize: 14 }}>
                        {errors.cpassword.message}
                      </p>
                    )}
                  </div>
                  <div className="mt-6">
                    <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform rounded-md bg-navbar_color">
                      {userLang.startsWith('no') || userLang.startsWith('nb') ? `Lag bruker` : `Sign Up`}
                    </button>
                  </div>
                </form>

                <p className="mt-8 text-xs font-light text-center text-gray-700">
                  {" "}
                  {userLang.startsWith('no') || userLang.startsWith('nb') ? `Har du allerede en konto?` : `Already have an account?`}
                  {" "}
                  <Link to={"/login"}
                    href="#"
                    className="font-medium text-navbar_color hover:underline"
                  >
                    {userLang.startsWith('no') || userLang.startsWith('nb') ? `Logg inn` : `Sign In`}
                  </Link>
                </p>
              </div>
        }

      </div>
    </>
  );
};

export default SignUp;
