import React, { useCallback, useEffect, useRef, useState } from "react";
const { default: axios, } = require('axios');
import { parseJWT } from "../../../utils";
import { toast } from "react-toastify";
import { ReactComponent as MicIcon } from "../../../assets/svg/mic.svg";
import { ReactComponent as SendMessageIcon } from "../../../assets/svg/sendmessage.svg";
import { ReactComponent as RecordingIcon } from "../../../assets/svg/recording.svg";
import { ReactComponent as ResizeIcon } from "../../../assets/svg/resize.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import { LoadingBar } from "../../LoadingBar";
import { useTranslation } from 'react-i18next';
import {marked} from 'marked';
import DOMPurify from 'dompurify';
interface Message {
  isUserMessage: boolean;
  text: string;
  id: string;
  documents: string[],
  streaming: boolean,
}

/**
 * Generates a unique ID by combining the current timestamp and a random number.
 *
 * @return {string} The generated unique ID.
 */
function generateUniqueId() {
  const timestamp = Date.now();
  const randomNumber = Math.random();
  const hexadecimalString = randomNumber.toString(16);
  return `id-${timestamp}-${hexadecimalString}`;
}

interface ChatProps {
  selectedPrompt: any,
  handleDocumentClick: any,
  isChatWindowClosed: boolean,
  setChatWindow: any,
  openedDocument: string,
  setDocumentUpdated: any,
  isSmallChatWindowOpen: boolean,
  setSmallChatWindowOpen: any,
  selectedDocumentList: any[]
  selectedTab: string
  previousOpenedDocument: string
  selectedFolderName: string
  isFolderContainFulldocuments: boolean
  fetchChatHistory: any
  messages: Message[]
  setMessages: any
  selectedFolderID: any
}
/**
 * Renders a chat component with the ability to send messages, record audio, and stream text extraction.
 *
 * @param {ChatProps} props - The props for the chat component.
 * @param {string} props.selectedPrompt - The selected prompt for the chat.
 * @param {Function} props.handleDocumentClick - The function to handle document click events.
 * @param {boolean} props.isChatWindowClosed - Indicates if the chat window is closed.
 * @param {Function} props.setChatWindow - The function to set the chat window state.
 * @param {string} props.openedDocument - The opened document for the chat.
 * @param {Function} props.setDocumentUpdated - The function to set the document updated state.
 * @param {boolean} props.isSmallChatWindowOpen - Indicates if the small chat window is open.
 * @param {Function} props.setSmallChatWindowOpen - The function to set the small chat window state.
 * @param {Array} props.selectedDocumentList - The list of selected documents for the chat.
 * @param {string} props.selectedTab - The selected tab for the chat.
 * @param {string} props.previousOpenedDocument - The previous opened document for the chat.
 * @param {string} props.selectedFolderName - The selected folder name for the chat.
 * @param {boolean} props.isFolderContainFulldocuments - Indicates if the folder contains full documents.
 * @param {Function} props.fetchChatHistory - The function to fetch chat history.
 * @param {Array} props.messages - The array of chat messages.
 * @param {Function} props.setMessages - The function to set the chat messages state.
 * @return {JSX.Element} The rendered chat component.
 */
export const Chat: React.FC<ChatProps> = ({ selectedPrompt, handleDocumentClick, isChatWindowClosed, setChatWindow, openedDocument, setDocumentUpdated, isSmallChatWindowOpen, setSmallChatWindowOpen, selectedDocumentList, selectedTab, previousOpenedDocument, selectedFolderName, isFolderContainFulldocuments, fetchChatHistory, messages, setMessages, selectedFolderID}) => {
  let user = parseJWT(localStorage.getItem("tender_auth"));
  const ref = useRef<HTMLParagraphElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  let timer: any = null
  const [isRecording, setRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const { t } = useTranslation();
  const handleScroll = useCallback(() => {
    if (ref.current) {
      scrollToBottom(ref.current);
    }
  }, []);


  const [isLoading, setLoading] = useState(false);
  const [editedMessageIndex, setEditedMessageIndex] = useState<any>(null);

  useEffect(() => {
    fetchChatHistory();
    handleScroll()
  }, [])


  useEffect(() => {
    if (selectedPrompt?.text) {
      /**
       * Fetches data from the server based on the selected prompt and appends it to the messages.
       *
       * @return {Promise<void>} A promise that resolves when the data is fetched and the messages are updated.
       */
      const fetchData = async () => {
        let response = await getAnswer(selectedPrompt?.text, "Insert", "", messages.length);
        if (response.status === 200) {
          fetchChatHistory();
          handleScroll();
        }
        setLoading(false);
      }
      fetchData();
      handleScroll();
    }
  }, [selectedPrompt?.text])

  const [userInput, setUserInput] = useState("");
  /**
   * Handles the Enter key press event and submits the user input if the Shift key is not pressed.
   *
   * @param {any} event - The event object representing the key press.
   * @return {void} This function does not return anything.
   */
  const onEnterPress = (event: any) => {
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault();
      submit();
    }
  }

  /**
   * An asynchronous function that submits user input, retrieves an answer, and updates chat history.
   *
   * @return {Promise<void>} A promise that resolves once the submission is completed.
   */
  const submit = async () => {
    let response = await getAnswer(userInput, "Insert", "", messages.length);
    if (response.status === 200) {
      fetchChatHistory();
      handleScroll()
    }
    setLoading(false);
  };

  /**
   * An asynchronous function that retrieves an answer based on user input and performs various actions.
   *
   * @param {string} userText - The text input provided by the user.
   * @param {string} action - The action to be performed.
   * @param {string} [message_id=""] - The ID of the message.
   * @param {number} index - The index value.
   * @return {Promise<any>} A promise that resolves with the response from the API.
   */
  const getAnswer = async (userText: string, action: string, message_id: string = "", index: number) => {
    setLoading(true);
    let params = {
      email: user.sub.email,
      question: userText,
      openedDocument: openedDocument,
      selectedDocumentList: selectedDocumentList.map(item => item.document),
      action: action,
      message_id: message_id,
      tender_id: selectedFolderID,
      selectedFolderID: selectedFolderID
    }
    setUserInput("");
    let newItems: Message[] = [...messages]
    if (action == "Insert") {
      setEditedMessageIndex((newItems.length + 2))
      const UserMessage: Message = {
        isUserMessage: true,
        text: userText,
        id: generateUniqueId(),
        documents: [],
        streaming: false
      };
      const AIMessage: Message = {
        isUserMessage: false,
        text: "",
        id: generateUniqueId(),
        documents: [],
        streaming: false
      }
      newItems.push(...[UserMessage, AIMessage]);
      setMessages(newItems);
      setTimeout(handleScroll, 0);
      index = newItems.length
    }
    try {
      const response: any = await fetch(process.env.REACT_APP_API_URL + "api/getAnswer", {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
          'Content-Type': 'application/json'
        }
      });
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let isStarted = false;
      setLoading(false)
      setEditedMessageIndex(null)
      while (!done) {
        const { value, done: doneReading } = await reader.read();
        const updatedMessages: Message[] = [...newItems];
        if (!isStarted) {
          updatedMessages[index - 1].text = ''
          updatedMessages[index - 1].documents = []
        }
        done = doneReading;
        const chunkValue = decoder.decode(value);
        var regex = /json_data:(\{.*?\})/g;
        var match = regex.exec(chunkValue);
        if (match && match.length >= 2) {
          console.log(chunkValue)
          var jsonValue = match[1];
          let json = JSON.parse(jsonValue)
          updatedMessages[index - 1].documents = json.doc_id
          setMessages(updatedMessages)
          if (json?.documentUpdated) {
            setDocumentUpdated(true)
          } else {
            setDocumentUpdated(false)
          }
        } else {
          updatedMessages[index - 1].text = updatedMessages[index - 1].text + chunkValue
          updatedMessages[index - 1].streaming = true;
          if (!chunkValue.includes("</")) {
            setMessages(updatedMessages)
            handleScroll();
          }
        }
        isStarted = true
        if (done) {
          updatedMessages[index - 1].streaming = false;
          setMessages(updatedMessages)
        }
      }
      return response
    } catch (error) {
      console.log(error)
      return false
    }

  }

  /**
   * Asynchronously handles the submission of an edit action.
   *
   * @param {any} index - The index of the message being edited.
   * @param {string} text - The new text of the message.
   * @param {string} message_id - The ID of the message being edited.
   * @return {Promise<void>} A promise that resolves when the submission is complete.
   */
  const handleSubmitFromEditAction = async (index: any, text: string, message_id: string) => {
    if (messages.length >= (index + 1)) {
      messages.map((message, i) => {
        if (index + 1 === i) {
          setEditedMessageIndex(index + 2)
        }
      })
      let response = await getAnswer(text, "Update", message_id, index + 2);
      if (response.status === 200) {
        fetchChatHistory();
        handleScroll
      }
      setEditedMessageIndex(null)
      setLoading(false);
    }
  }

  /**
   * Calls the handleDocumentClick function with the given document string.
   *
   * @param {string} document - The document string to be passed to handleDocumentClick.
   * @return {void} This function does not return anything.
   */
  const documentClick = (document: string) => {
    handleDocumentClick(document)
  }

  /**
   * Handles the click event on the mic icon.
   *
   * @param {any} e - The event object
   */
  const handleMicIconClick = (e: any) => {
    clearTimeout(timer)
    if (e.detail == 2) {
    } else {
      timer = setTimeout(async () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          console.log('getUserMedia supported');

          navigator.mediaDevices.getUserMedia(
            // constraints - only audio needed for this app
            {
              audio: true
            }).then(function (stream) {
              const mediaRecorder = new MediaRecorder(stream);

              mediaRecorder.addEventListener('dataavailable', (e) => {
                if (e.data.size > 0) {
                  chunksRef.current.push(e.data);
                }
              });

              mediaRecorder.addEventListener('stop', async () => {
                const audioBlob = new Blob(chunksRef.current, { type: 'audio/mp3' });
                console.log(audioBlob)
                await sendMP3(audioBlob);
                chunksRef.current = [];
              });

              mediaRecorder.start();
              setRecording(true);
              mediaRecorderRef.current = mediaRecorder;
            })
            .catch(function (err) {
              console.log('The following getUserMedia error occurred: ' + err);
              toast.warning(t("You need to have mic"), {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
              });
            });
        } else {
          console.log('getUserMedia not supported on your browser!');
        }

      }, 200);
    }
  }

  /**
   * Handles the click event when the record stop button is clicked.
   *
   * @param {any} e - The event object.
   * @return {Promise<void>} A promise that resolves when the function completes.
   */
  const handleRecordStopClick = async (e: any) => {
    if (isChatWindowClosed) {
      setSmallChatWindowOpen(true)
    }
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    } else {
      console.log("You need to have mic")
    }
    setRecording(false);
  }

  /**
   * Sends an MP3 file to the server for text extraction.
   *
   * @param {Blob} mp3Blob - The MP3 file to be sent.
   * @return {Promise<void>} A promise that resolves when the extraction is complete.
   */
  const sendMP3 = async (mp3Blob: Blob) => {
    const formData = new FormData();
    formData.append('file', mp3Blob, 'audio.mp3');
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "api/getTextFromVoice", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response)
      if (response?.data?.message && response.status === 200) {
        let chat_response = await getAnswer(response.data.message, "Insert", "", messages.length);
        if (chat_response.status === 200) {
          fetchChatHistory();
          handleScroll();
        }
      }

    } catch (error) {
      console.error('Error getTextFromVoice', error);
    }
  };

  const handleResizeClick = () => {
    setChatWindow(false)
    setSmallChatWindowOpen(false)
  }

  const handleCloseClick = () => {
    setSmallChatWindowOpen(false);
    setChatWindow(true)
  }

  const handleStopResponding = async (index: number) => {
    console.log(index)
    try {
      await axios.post(process.env.REACT_APP_API_URL + "api/stopStreaming", { "email": user.sub.email }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
          "Content-Type": "application/json",
        }
      });
      const updatedMessages: Message[] = [...messages];
      updatedMessages[index].streaming = false;
      setMessages(updatedMessages)
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div>
      {
        isSmallChatWindowOpen ?
          <div className={`visible mt-[calc(100vh-535px)] rounded-xl	border-[1px] border-b-solid border-[#000000] border-opacity-30 pb-[20px] w-[500px] absolute right-[0px] bottom-[10px] bg-bg_body`}>
            <div className='relative'>
              <button className='absolute right-[1%] top-[-40px]' >
                <CloseIcon className='text-black h-9 w-9' onClick={() => handleCloseClick()}></CloseIcon>
              </button>
              <button className='absolute right-[0] top-[-40px] mr-[40px]'>
                <ResizeIcon className='text-black h-9 w-9' onClick={() => handleResizeClick()}></ResizeIcon>
              </button>
            </div>
            <div className="h-full">
              <div className="h-full">
                <div className="flex flex-col justify-between flex-grow h-full">
                  <div className="flex-grow safari-scroll scrollbar-hide h-[220px]" ref={ref}>
                    <ul>
                      {
                        messages.length > 0 && messages.length % 2 == 1 ?
                          <li key={messages[messages.length - 1].id}>
                            <UserMessage msg={messages[messages.length - 1]} index={messages.length - 1} handleSubmitFromEditAction={handleSubmitFromEditAction} />
                          </li> : <></>
                      }
                      {
                        messages.length > 0 && messages.length % 2 == 0 ?
                          <>
                            <li key={messages[messages.length - 2].id}>
                              <UserMessage msg={messages[messages.length - 2]} index={messages.length - 2} handleSubmitFromEditAction={handleSubmitFromEditAction} />
                            </li>
                            <li key={messages[messages.length - 1].id}>
                              {
                                isLoading ? (<LoadingBar></LoadingBar>) : <BotMessage msg={messages[messages.length - 1]} index={messages.length - 1} documentClick={documentClick} handleStopResponding={handleStopResponding} />
                              }

                            </li>
                          </> : <></>
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center relative space-x-2 h-[100px]">
              <textarea id="message" rows={4} className="w-[calc(95%-95px)] absolute left-[0px] bottom-[-10px] ml-[2.5%] h-[85px] block p-2.5 text-lg text-black bg-main_bg rounded-lg border border-[#000000] border-opacity-30" placeholder={t("Ask me anything...")} onChange={(e) => setUserInput(e.target.value)} value={userInput} onKeyDown={(e) => onEnterPress(e)} ref={textareaRef}>
              </textarea>
              <SendMessageIcon className="absolute right-[calc(120px)] z-[10] top-[55px] cursor-pointer" onClick={() => { submit() }}></SendMessageIcon>
              {
                isRecording ?
                  <RecordingIcon className={`fixed h-[85px] bottom-[25px] right-[10px] w-[85px] cursor-pointer`} onClick={(e) => handleRecordStopClick(e)}></RecordingIcon> :
                  user?.sub?.membership == "trial" ?
                    <MicIcon className={`fixed h-[85px] bottom-[25px] right-[10px] w-[85px] cursor-pointer`} onClick={(e) => {
                      toast.error(t("Please upgrade your license to use this functionality"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                      })
                    }}></MicIcon>
                    : <MicIcon className={`fixed h-[85px] bottom-[25px] right-[10px] w-[85px] cursor-pointer`} onClick={(e) => handleMicIconClick(e)}></MicIcon>
              }
            </div>
          </div> :
          <div>
            <div className="h-full">
              <div className="h-full">
                <div className="flex flex-col justify-between flex-grow h-full">
                  <div className="flex-grow safari-scroll scrollbar-hide h-[300px] md:h-[calc(100vh-270px)] mt-[5px]" ref={ref}>
                    <ul>
                      {!isChatWindowClosed && messages?.map((msg, index) => {
                        return (
                          <div key={msg.id}>
                            <li >
                              {msg.isUserMessage ? (
                                <UserMessage msg={msg} index={index} handleSubmitFromEditAction={handleSubmitFromEditAction} />
                              ) : (index + 1) == editedMessageIndex && isLoading ? (<LoadingBar></LoadingBar>) : (
                                <BotMessage msg={msg} index={index} documentClick={documentClick} handleStopResponding={handleStopResponding} />
                              )}
                            </li>
                          </div>
                        );
                      })}

                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={`flex items-center space-x-2 relative ${isChatWindowClosed ? "h-[80px]" : "h-[100px]"} mt-[5px]`}>
              <textarea id="message" rows={4} className="w-[calc(95%-95px)] absolute left-[0px] bottom-[-10px] ml-[2.5%] h-[85px] block p-2.5 text-lg text-black bg-main_bg rounded-lg border border-[#000000] border-opacity-30" placeholder={t("Ask me anything...")} onChange={(e) => setUserInput(e.target.value)} value={userInput} onKeyDown={(e) => onEnterPress(e)} ref={textareaRef}>
              </textarea>
              <SendMessageIcon className="absolute right-[calc(120px)] z-[10] top-[55px] cursor-pointer" onClick={() => { submit() }}></SendMessageIcon>
              {
                isRecording ?
                  <RecordingIcon className={`fixed h-[85px] ${isChatWindowClosed ? "bottom-[10px] right-[10px]" : "bottom-[10px] right-[10px]"} w-[85px] cursor-pointer visible`} onClick={(e) => handleRecordStopClick(e)}></RecordingIcon> :
                  user?.sub?.membership == "trial" ?
                    <div className={`fixed rounded-full flex justify-center items-center bg-main_color_2 h-[85px] ${isChatWindowClosed ? "bottom-[10px] right-[10px]" : "bottom-[10px] right-[10px]"} w-[85px] cursor-pointer visible`} onClick={(e) => {
                      toast.error(t("Please upgrade your license to use this functionality"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                      })
                    }}>
                      {isChatWindowClosed==true ? <img className="w-12" src={process.env.PUBLIC_URL + '/img/Pi7_Filter_page2.png'} ></img> : <MicIcon></MicIcon>}
                    </div>
                    : <div className={`fixed  flex justify-center items-center rounded-full bg-main_color_2 h-[85px] ${isChatWindowClosed ? "bottom-[10px] right-[10px]" : "bottom-[10px] right-[10px]"} w-[85px] cursor-pointer visible`} onClick={(e) => {
                      if (isChatWindowClosed) {
                        setChatWindow(false)
                        setSmallChatWindowOpen(false)
                      } else {
                        handleMicIconClick(e)
                      }
                    }}>
                      {isChatWindowClosed==true ? <img className="w-12" src={process.env.PUBLIC_URL + '/img/Pi7_Filter_page2.png'} ></img> : <MicIcon></MicIcon>}
                    </div>
              }
            </div>
          </div>
      }

    </div>
  );
}

export const UserMessage = ({ msg, index, handleSubmitFromEditAction }: { msg: Message, index: any, handleSubmitFromEditAction: any }) => {
  const [isEditing, setEditing] = useState(false);
  const [prompt, setPrompt] = useState(msg.text);
  const handleEditPrompt = () => {
    setEditing(true);
  }

  const handleSubmit = () => {
    handleSubmitFromEditAction(index, prompt, msg.id);
    setEditing(false);
  }

  const onEnterPress = (event: any) => {
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault();
      handleSubmitFromEditAction(index, prompt, msg.id);
      setEditing(false);
    }
  }
  return (
    <div className="flex items-center p-3 space-x-8 text-xl text-left text-black">
      <div className="w-full text-xl rounded-md text-base-content ">
        {
          isEditing ? (
            <input className="w-[100%] p-[3px]" value={prompt} onChange={(e) => setPrompt(e.target.value)} autoFocus onKeyDown={(e) => onEnterPress(e)} />
          ) : <div className="min-h-[20px] flex flex-col items-start gap-4 whitespace-pre-wrap break-words bg-main_bg px-[15px] py-[10px] rounded-[8px]">{prompt}</div>
        }
      </div>
      <div className="daisy-placeholder daisy-avatar">
        <div className="w-8 text-3xl font-black daisy-mask daisy-mask-square bg-primary text-accent">
          {
            isEditing ? (
              <svg className="w-6 h-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => handleSubmit()}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            ) : (
              <svg className="w-6 h-6 text-black" onClick={() => handleEditPrompt()} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />  <line x1="16" y1="5" x2="19" y2="8" />
              </svg>
            )
          }
        </div>
      </div>
    </div>
  );
};

export const BotMessage = ({ msg, index, documentClick, handleStopResponding }: { msg: Message, index: number, documentClick: any, handleStopResponding: any }) => {
  const { t } = useTranslation();
  const copyToClipBoard = (message: string) => {
    navigator.clipboard.writeText(message);
    toast.success(t("Copied to Clipboard!"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: 0,
      toastId: "my_toast",
    });
  }

  const handleDocumentClick = (document: string) => {
    documentClick(document)
  }
  const createMarkup = (text) => {
    // Convert the Markdown text to HTML using marked
    const rawMarkup = marked(text);
    // Sanitize the HTML to prevent XSS attacks
    const cleanMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: cleanMarkup };
  };

  return (
    <div className="flex items-center p-3 space-x-8 text-xl text-left text-black">
      <div className="rounded-[8px] text-xl text-base-content w-full bg-main_bg px-[15px] py-[10px]">
        {msg.text?.length ? (
          <>
            <div className="flex items-center mb-[5px] pl-5">
              {/* <span className="font-bold">{t("AI")}&nbsp;&nbsp;</span> */}
              
              <div className="text-xl prose text-black markdown" dangerouslySetInnerHTML={createMarkup(msg.text.replace("```html", "").replace("```", "").trim())}>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {msg.documents?.length > 0 ? (
          <>
            {msg.documents[0] != "ChatHistory" ? <span>{t("Source")}</span> : <></>}
            {msg.documents.map((document: string) => document != "ChatHistory" ?
              (
                <React.Fragment key={document}>
                  <span className="font-bold cursor-pointer underline pl-[5px]" onClick={() => handleDocumentClick(document)}>
                    {' ' + document}
                  </span>
                </React.Fragment>
              ) : null)
            }
          </>
        ) : null}
        {
          msg.streaming ? (
            <div className="flex justify-center">
              <div className='flex items-center'>
                <button className="bg-main_color_2 text-white rounded p-[10px]" onClick={() => { handleStopResponding(index) }}>{t("Stop Responding")}</button>
              </div>
            </div>
          ) : null
        }
      </div>
      <div className="daisy-placeholder daisy-avatar ml-[6px]">
        <div className="w-8 text-3xl font-black daisy-mask daisy-mask-square bg-secondary text-accent">
          <svg onClick={() => copyToClipBoard(msg.text.trim())} className="text-black cursor-pointer h-7 w-7" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x="8" y="8" width="12" height="12" rx="2" />  <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" /></svg>
        </div>
      </div>
    </div>
  );
};

export const scrollToBottom = (element: HTMLElement) => {
  element.scroll({
    behavior: "auto",
    top: element.scrollHeight,
  });
};