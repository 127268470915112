import React, { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error('Error caught by error boundary:', error);
        console.error('Error info:', errorInfo);
        this.setState({ hasError: true });
    }

    render(): ReactNode {
        if (this.state.hasError) {
            // Display the error UI
            return (
                <div className="flex justify-center items-center h-screen bg-purple-600">
                    <div id="error-page">
                        <h1 className="lg:text-6xl font-bold text-2xl text-white">Oops!</h1>
                        <p className="text-xl text-white">
                            Sorry, an unexpected error has occurred. Please reload the webpage.
                        </p>
                    </div>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;