import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon1 } from "../../assets/svg/uncompleted.svg";
import { ReactComponent as Icon2 } from "../../assets/svg/insight_refresh.svg";
import { ReactComponent as Icon3 } from "../../assets/svg/complete.svg";
interface CustomToastProps {
    currentStep: number;
}
// Your custom toast component with TypeScript
const CustomToast: React.FC<CustomToastProps> = ({ currentStep }) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className='flex items-center p-1'>
                {currentStep === 1 ? <Icon2 className='animate-spin' /> : <Icon3 />} &nbsp;&nbsp;{t("Documents downloaded")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 2 ? <Icon2 className='animate-spin' /> : currentStep <= 1 ? <Icon1 /> : <Icon3 />} &nbsp;&nbsp;{t("AI learning the documents")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 3 ? <Icon2 className='animate-spin' /> : currentStep <= 2 ? <Icon1 /> : <Icon3 />} &nbsp;&nbsp;{t("Getting Insights")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 4 ? <Icon2 className='animate-spin' /> : currentStep <= 3 ? <Icon1 /> : <Icon3 />} &nbsp;&nbsp;{t("Getting Summaries")}
            </div>
            <div className='flex items-center p-1'>
                {currentStep === 5 ? <Icon2 className='animate-spin' /> : currentStep <= 4 ? <Icon1 /> : <Icon3 />} &nbsp;&nbsp;{t("Getting documents for submission")}
            </div>
        </div>
    )
}
export default CustomToast;